import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Row,
  Tooltip,
  Switch,
  Divider,
  InputNumber,
  DatePicker,
  Tag,
  Spin,
  Col,
} from 'antd'
import {
  buffers,
  containers,
  controls,
  extractionMethods,
  isClinical,
  isMetagenome,
  isMicrobial,
  isMicrobialWGS,
  isRML,
  isSarsCovid,
  labCodes,
  labs,
  orderTypes,
  plateLayout,
  preprocessingMethods,
  primers,
  priorities,
  regions,
  selectionCriteria,
} from '../../services/helpers/constants'
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from './Forms.module.css'

import {
  getBackgroundColor,
  getConcentrationRules,
  getIsPlate,
  isPANPAZApplication,
  validateApplication,
  validateWellIsFree,
} from '../../pages/OrderForm/helpers'
import { DeletePopConfirm } from '../DeletePopConfirm'
import { Sex } from '../../services/interfaces'
import { indexSequences } from '../../services/helpers/indexSequences'
import { getIndexSequence } from '../../services/helpers/helpers'
import { getApplication } from '../../services/StatusDbApi'
import { UserContext } from '../../services/contexts/userContext'
import { SubjectIdField } from './Fields/SubjectIdField'

export const StandaloneSampleForm = ({
  index,
  remove,
  options,
  form,
  analysisType,
  skipReceptionControl,
}) => {
  const [isFinalized, setIsFinalized] = useState<boolean>(false)
  const [finalizedSample, setFinalizedSample] = useState<any>()
  const [hasContainer, setHasContainer] = useState(false)
  const [isPlate, setIsPlate] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [containerName, setContainerName] = useState<string>()
  const [sampleId, setSampleId] = useState<string>()
  const [organisms] = useState<any[]>(options?.organisms)
  const [indexSequence, setIndexSequence] = useState<string>('')
  const [application, setApplication] = useState<string>()
  const [isOtherSource, setIsOtherSource] = useState(false)
  const [isOtherOrganism, setIsOtherOrganism] = useState(false)
  const [hasIndex, setHasIndex] = useState(true)
  const [applicationObj, setApplicationObj] = useState<any>()
  const [containerNameRequirement, setContainerNameRequirement] = useState<boolean>(false)
  const [source, setSource] = useState<string>()
  const [subjectId, setSubjectId] = useState<string>()
  const [localSample] = useState<any>(form.getFieldValue('samples')[index])
  const otherOrganism = 'other'
  const userContext = useContext(UserContext)

  const prefillConditionalFields = useCallback(() => {
    const {
      container,
      name,
      container_name,
      application,
      source,
      tumour,
      index: indexName,
      index_number,
      organism,
    } = form.getFieldValue('samples')[index] || ''
    setIndexSequence(getIndexSequence(indexName, index_number))
    setSampleId(name)
    setHasIndex(indexName !== 'NoIndex')
    setApplication(application)
    if (application && skipReceptionControl) {
      getApplication(userContext, application).then((res) => {
        setApplicationObj(res)
      })
    }
    setIsOtherOrganism(organism === otherOrganism)
    setIsPlate(getIsPlate(container))
    setContainerName(container_name)
    setHasContainer(!container?.includes('No container'))
    setContainerNameRequirement(container === '96 well plate')
    if (source) {
      setIsOtherSource(source?.includes('other'))
    }
    setSource(source)
    if (!tumour) form.setFieldValue(['samples', index, 'tumour'], false)
  }, [form, index, skipReceptionControl, userContext])

  const finalizeSample = useCallback(
    async ({ errorFields }: any) => {
      let hasSampleError = false
      errorFields?.map(({ name }) => {
        if (name[1] === index) {
          hasSampleError = true
          setIsFinalized(false)
        }
      })
      setIsFinalized(!hasSampleError)
      setFinalizedSample(localSample)
    },
    [index, localSample]
  )

  const validateSample = useCallback(() => {
    if (Object.keys(localSample).length > 1) {
      form
        .validateFields()
        .then(() => {
          finalizeSample([])
          setIsLoading(false)
        })
        .catch((errors) => {
          finalizeSample(errors)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [finalizeSample, form, localSample])

  useEffect(() => {
    prefillConditionalFields()
  }, [prefillConditionalFields])

  useEffect(() => {
    if (localSample && Object.keys(localSample).length > 1) {
      setTimeout(() => {
        validateSample()
        setIsLoading(false)
      }, 2000)
    } else {
      setIsLoading(false)
    }
  }, [validateSample, form, index, localSample])

  return (
    <Card
      bodyStyle={{
        backgroundColor: getBackgroundColor(index),
      }}
      style={{ margin: 3, width: '100%' }}
      id={`sample ${index}`}
    >
      {isFinalized ? (
        <>
          <Row gutter={[16, 16]}>
            <Col flex="auto">
              <h2>{finalizedSample?.name}</h2>
            </Col>
            <Col flex="none">
              <div className={styles.savedCaseColumn}>
                <Button icon={<EditTwoTone />} onClick={() => setIsFinalized(false)}>
                  Edit
                </Button>
                <DeletePopConfirm
                  itemType={'sample'}
                  action={() => {
                    remove(index)
                  }}
                  itemName={sampleId}
                  triggerComponent={
                    <Button icon={<DeleteTwoTone />} block>
                      Sample
                    </Button>
                  }
                />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Spin tip="Validating samples..." size="small" spinning={isLoading}>
            <Row className={styles.row}>
              <Form.Item
                label="Name"
                name={[index, 'name']}
                rules={[
                  {
                    required: true,
                    message: 'required',
                  },
                  {
                    message: 'Can include only numbers, letters and dash',
                    pattern: new RegExp(/^[a-zA-Z0-9-]*$/),
                  },
                ]}
                required
              >
                <Input
                  style={{ width: 200 }}
                  onChange={(e) => {
                    setSampleId(e.target.value)
                  }}
                />
              </Form.Item>
              {!isClinical(analysisType) && (
                <Form.Item label="Control" name={[index, 'control']}>
                  <Select style={{ width: 90 }}>
                    {controls.map((control) => (
                      <Select.Option key={control} value={control}>
                        {control}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label="Priority"
                rules={[{ required: true }]}
                required
                name={[index, 'priority']}
              >
                <Select style={{ width: 120 }}>
                  {priorities.map(({ value, text }) => (
                    <Select.Option key={value} value={value}>
                      {text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={() => validateSample()}>
                  Save
                </Button>
              </Form.Item>
              {!isRML(analysisType) && !isMicrobial(analysisType) && (
                <Tooltip title="Require quality control to continue">
                  <Form.Item
                    label="Req. QC"
                    name={[index, 'require_qc_ok']}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Tooltip>
              )}
            </Row>
            <Row className={styles.row}>
              <Form.Item
                label="Application tag"
                name={[index, 'application']}
                rules={[
                  { required: true },
                  {
                    validator: (rule, value) => {
                      return validateApplication(value, options.applications, analysisType)
                    },
                  },
                ]}
                required
              >
                <Select
                  style={{ width: 170 }}
                  onChange={async (e) => {
                    setApplication(e)
                    if (e && skipReceptionControl) {
                      const newApplicationObj = await getApplication(userContext, e)
                      setApplicationObj(newApplicationObj)
                    } else {
                      setApplicationObj(null)
                    }
                  }}
                >
                  {orderTypes
                    .find((type) => type.orderType === analysisType)
                    ?.['applications'].map((application) =>
                      options.applications[application]?.map((localApp) => (
                        <Select.Option key={localApp} value={localApp}>
                          {localApp}
                        </Select.Option>
                      ))
                    )}
                </Select>
              </Form.Item>
              {isPANPAZApplication(application) && (
                <Form.Item
                  label="Bait set"
                  name={[index, 'capture_kit']}
                  rules={[{ required: true }]}
                  required
                >
                  <Select style={{ width: 260 }}>
                    {options?.beds?.map((bed) => (
                      <Select.Option key={bed} value={bed}>
                        {bed}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {isClinical(analysisType) && (
                <>
                  <Form.Item
                    label="Sex"
                    name={[index, 'sex']}
                    rules={[{ required: true }]}
                    required
                  >
                    <Select style={{ width: 100 }}>
                      {Object.keys(Sex).map((sex) => (
                        <Select.Option key={sex} value={sex}>
                          {sex}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Is Tumour" name={[index, 'tumour']} valuePropName="checked">
                    <Switch />
                  </Form.Item>
                  <SubjectIdField
                    caseName={sampleId + 'fam'}
                    sampleId={sampleId}
                    subjectId={subjectId}
                    required={false}
                    index={index}
                    isExisting={false}
                  ></SubjectIdField>
                </>
              )}
              {isMicrobial(analysisType) && (
                <>
                  <Form.Item
                    label="Organism"
                    rules={[{ required: true }]}
                    required
                    name={[index, 'organism']}
                  >
                    <Select
                      style={{ width: 280 }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => {
                        setIsOtherOrganism(e === otherOrganism)
                        form.setFieldValue(
                          ['samples', index, 'reference_genome'],
                          organisms.find((organism) => organism.internal_id === e)?.reference_genome
                        )
                      }}
                      options={organisms.map(({ internal_id, verified }) => ({
                        label: (
                          <div>
                            {internal_id} <>{verified && <Tag color={'green'}>Verified</Tag>}</>
                          </div>
                        ),
                        value: internal_id,
                      }))}
                    />
                  </Form.Item>
                  {isOtherOrganism && (
                    <Form.Item
                      label={' Other organism'}
                      name={[index, 'organism_other']}
                      rules={[{ required: !isOtherOrganism }]}
                      required={!isOtherOrganism}
                    >
                      <Input style={{ width: 150 }} />
                    </Form.Item>
                  )}
                  <Form.Item
                    label={
                      <div>
                        Reference{' '}
                        <a
                          href={'https://www.ncbi.nlm.nih.gov/genome/browse/#!/overview/'}
                          target="_blank"
                          rel="noreferrer"
                        >
                          genome
                        </a>
                      </div>
                    }
                    name={[index, 'reference_genome']}
                    rules={[{ required: true }]}
                    required
                  >
                    <Input style={{ width: 150 }} placeholder={'e.g. NC_001928'} />
                  </Form.Item>
                </>
              )}
            </Row>
            {isRML(analysisType) && (
              <Row className={styles.row}>
                <Form.Item
                  label="Index"
                  name={[index, 'index']}
                  required
                  rules={[{ required: true }]}
                >
                  <Select style={{ width: 340 }} onChange={() => prefillConditionalFields()}>
                    {Object.keys(indexSequences).map((index) => (
                      <Select.Option key={index}>{index}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {hasIndex && (
                  <div>
                    <Form.Item
                      label="Index number"
                      name={[index, 'index_number']}
                      required
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        style={{ width: 410 }}
                        onChange={() => prefillConditionalFields()}
                        addonAfter={<div style={{ width: 330 }}>{indexSequence}</div>}
                      />
                    </Form.Item>
                  </div>
                )}
              </Row>
            )}
            <Row className={styles.row}>
              <Form.Item label="Comment" name={[index, 'comment']}>
                <Input style={{ width: 510 }} />
              </Form.Item>
              {isRML(analysisType) && (
                <>
                  <Form.Item label="Pool" name={[index, 'pool']}>
                    <Input style={{ width: 90 }} />
                  </Form.Item>
                  <Form.Item label="Pool concentration" name={[index, 'concentration']}>
                    <InputNumber style={{ width: 55 }} />
                  </Form.Item>
                </>
              )}
            </Row>
            <Divider style={{ marginTop: '-2px' }} />
            <Row className={styles.row}>
              {!isRML(analysisType) && (
                <Tooltip title={'Elution buffer'}>
                  <Form.Item
                    label="Buffer"
                    name={[index, 'elution_buffer']}
                    required
                    rules={[
                      { required: true },
                      {
                        validator: (rule, value) => {
                          return skipReceptionControl && value?.includes('Other')
                            ? Promise.reject()
                            : Promise.resolve()
                        },
                        message: skipReceptionControl
                          ? 'Only Tris-HCl and nuclease-free water allowed when skipping reception control.'
                          : '',
                      },
                    ]}
                  >
                    <Select style={{ width: 240 }}>
                      {buffers.map((buffer) => (
                        <Select.Option key={buffer}>{buffer}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Tooltip>
              )}
              {(isMicrobialWGS(analysisType) || isSarsCovid(analysisType)) && (
                <Tooltip title={'DNA Extraction method'}>
                  <Form.Item
                    label="Extraction method"
                    name={[index, 'extraction_method']}
                    required
                    rules={[{ required: true }]}
                  >
                    <Select style={{ width: 240 }}>
                      {extractionMethods.map((method) => (
                        <Select.Option key={method}>{method}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Tooltip>
              )}
            </Row>
            {(isClinical(analysisType) || isMetagenome(analysisType)) && (
              <>
                <Row className={styles.row}>
                  <Form.Item
                    label="Source"
                    name={[index, 'source']}
                    rules={[{ required: true }]}
                    required
                  >
                    <Select style={{ width: 235 }} onChange={() => prefillConditionalFields()}>
                      {options?.sources?.analysis?.map((panel) => (
                        <Select.Option key={panel} value={panel}>
                          {panel}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {isOtherSource && (
                    <Form.Item
                      label={'Which other source?'}
                      name={[index, 'source_comment']}
                      rules={[{ required: true }]}
                      required
                    >
                      <Input placeholder={'Which other source?'} style={{ width: 245 }} />
                    </Form.Item>
                  )}
                </Row>
              </>
            )}
            {isSarsCovid(analysisType) && (
              <>
                <Tooltip title={'Pre-processing method'}>
                  <Form.Item
                    label="Pre-processing"
                    name={[index, 'pre_processing_method']}
                    rules={[{ required: true }]}
                    required
                  >
                    <Select style={{ width: 260 }}>
                      {preprocessingMethods.map((method) => (
                        <Select.Option key={method}>{method}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Tooltip>
                <Tooltip title={'Sequencing Platform and Primer Scheme'}>
                  <Form.Item
                    label="Platform/Primer"
                    name={[index, 'primer']}
                    rules={[{ required: true }]}
                    required
                  >
                    <Select style={{ width: 180 }}>
                      {primers.map((primer) => (
                        <Select.Option key={primer}>{primer}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </>
            )}

            {isSarsCovid(analysisType) && (
              <>
                <Row className={styles.row}>
                  <Form.Item
                    label="Region"
                    name={[index, 'region']}
                    rules={[{ required: true }]}
                    required
                  >
                    <Select style={{ width: 140 }}>
                      {regions.map((region) => (
                        <Select.Option key={region}>{region}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Selection criteria"
                    name={[index, 'selection_criteria']}
                    rules={[{ required: true }]}
                    required
                  >
                    <Select style={{ width: 230 }}>
                      {selectionCriteria.map((criterion) => (
                        <Select.Option key={criterion}>{criterion}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Collection date"
                    name={[index, 'collection_date']}
                    rules={[{ required: true }]}
                    required
                  >
                    <DatePicker />
                  </Form.Item>
                </Row>
                <Row className={styles.row}>
                  <Form.Item
                    label="Original lab"
                    name={[index, 'original_lab']}
                    rules={[{ required: true }]}
                    required
                  >
                    <Select style={{ width: 300 }}>
                      {labs.map((lab) => (
                        <Select.Option key={lab}>{lab}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>
              </>
            )}
            <Row className={styles.row}>
              <Form.Item
                label="Container"
                name={[index, 'container']}
                rules={[{ required: !isRML(analysisType) }]}
                required={!isRML(analysisType)}
              >
                <Select
                  style={{ width: 170 }}
                  onChange={(value) => {
                    prefillConditionalFields()
                    setContainerNameRequirement(value === '96 well plate')
                  }}
                >
                  {containers.map((container) => (
                    <Select.Option key={container} value={container}>
                      {container}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {hasContainer && (
                <>
                  <Form.Item
                    label="Container name"
                    name={[index, isRML(analysisType) ? 'rml_plate_name' : 'container_name']}
                    rules={[{ required: !isRML(analysisType) && containerNameRequirement }]}
                    required={!isRML(analysisType) && containerNameRequirement}
                  >
                    <Input
                      style={{ width: 207 }}
                      onChange={({ target }) => setContainerName(target.value)}
                    />
                  </Form.Item>{' '}
                  {isPlate && (
                    <Form.Item
                      label="Well"
                      name={[index, isRML(analysisType) ? 'well_position_rml' : 'well_position']}
                      rules={[
                        { required: true },
                        {
                          message: <div> This well already contains a sample </div>,
                          validator: (rule, well) => {
                            const samples = form.getFieldValue('samples')
                            return validateWellIsFree(well, containerName, samples)
                          },
                        },
                      ]}
                      required
                    >
                      <Select style={{ width: 70 }}>
                        {plateLayout().map((well) => (
                          <Select.Option key={well} value={well}>
                            {well}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Tooltip title={'Volume (μL). Must be between 20-130 μL'}>
                    <Form.Item
                      label="Volume (μL)"
                      name={[index, 'volume']}
                      rules={[
                        { required: true },
                        {
                          min: 20,
                          max: 130,
                          message: '20 to 130 μL',
                          type: 'number',
                        },
                      ]}
                      required
                    >
                      <InputNumber style={{ width: 60 }} />
                    </Form.Item>
                  </Tooltip>
                  {(isMicrobial(analysisType) || isMetagenome(analysisType)) && (
                    <Tooltip title={'Concentration (ng/uL)'}>
                      <Form.Item label="ng/uL" name={[index, 'concentration_sample']}>
                        <InputNumber style={{ width: 60 }} />
                      </Form.Item>
                    </Tooltip>
                  )}
                  {isRML(analysisType) ? (
                    <Tooltip title={'Sample concentration (nM)'}>
                      <Form.Item label="nM" name={[index, 'concentration_sample']}>
                        <InputNumber style={{ width: 65 }} />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Tooltip title={'Quantity (ng)'}>
                      <Form.Item label="ng" name={[index, 'quantity']}>
                        <Input type={'number'} style={{ width: 60 }} />
                      </Form.Item>
                    </Tooltip>
                  )}
                </>
              )}
              {isClinical(analysisType) && (
                <Tooltip title={'Concentration (ng/μL)'}>
                  <Form.Item
                    label="Concentration (ng/μL)"
                    name={[index, 'concentration_ng_ul']}
                    required={skipReceptionControl}
                    rules={
                      skipReceptionControl && applicationObj
                        ? getConcentrationRules(skipReceptionControl, applicationObj, source)
                        : []
                    }
                  >
                    <InputNumber style={{ width: 65 }} />
                  </Form.Item>
                </Tooltip>
              )}
            </Row>
            <DeletePopConfirm
              itemType={'sample'}
              action={() => {
                remove(index)
              }}
              itemName={sampleId}
              triggerComponent={
                <Button icon={<DeleteTwoTone />} block>
                  Sample
                </Button>
              }
            />
          </Spin>
        </>
      )}
    </Card>
  )
}
