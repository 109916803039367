import { Button, Form } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { StandaloneSampleForm } from './StandaloneSampleForm'
import React from 'react'

export const StandaloneSampleFormList = ({
  selectedAnalysisType,
  form,
  options,
  skipReceptionControl,
}) => (
  <Form.List name={'samples'}>
    {(samples, { add, remove }) => (
      <>
        <Button
          onClick={() => add()}
          icon={<PlusOutlined />}
          type={'primary'}
          style={{ marginBottom: 5, marginRight: 20 }}
        >
          New Sample
        </Button>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
          {samples?.map(({ key, name }) => {
            return (
              <StandaloneSampleForm
                key={key}
                index={name}
                remove={() => remove(name)}
                options={options}
                form={form}
                analysisType={selectedAnalysisType}
                skipReceptionControl={skipReceptionControl}
              />
            )
          })}
        </div>
      </>
    )}
  </Form.List>
)
