import { Button, Form, Input, Select, Card, Badge, Spin, Alert, Row, Col, Tag } from 'antd'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  carrierStatus,
  isBalsamic,
  isMIPDNA,
  isRNAFusion,
  isTomte,
  priorities,
  supportSystemURL,
} from '../../services/helpers/constants'
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from '@ant-design/icons'
import { CaseSampleForm } from './CaseSampleForm'
import {
  buildExistingSample,
  getBackgroundColor,
  handleTumorCaseCount,
  validateConcentrationLevel,
  validatePanels,
} from '../../pages/OrderForm/helpers'
import { Case, Parents, Sample } from '../../services/interfaces'
import { DeletePopConfirm } from '../DeletePopConfirm'
import { getExistingSamples } from '../../services/StatusDbApi'
import { UserContext } from '../../services/contexts/userContext'
import { isNil } from 'ramda'
import styles from '../../pages/OrderForm/OrderForm.module.css'
import localStyles from './Forms.module.css'
import { ErrorNotification } from '../../services/helpers/helpers'

export const CaseForm = ({
  caseName,
  remove,
  restField,
  options,
  form,
  customer,
  analysisType,
  skipReceptionControl,
}) => {
  const [isFinalized, setIsFinalized] = useState<boolean>(false)
  const [isExisting, setIsExisting] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasSamples, setHasSamples] = useState<boolean>(true)
  const [hasTumorError, setHasTumorError] = useState<boolean>(false)
  const [hasNoneTumorError, setHasNoneTumorError] = useState<boolean>(false)
  const [hasBalsamicSampleAmountError, setHasBalsamicSampleAmountError] = useState<boolean>(false)
  const [hasRNAFusionSampleAmountError, setHasRNAFusionSampleAmountError] = useState<boolean>(false)
  const [parentsSamples, setParentSamples] = useState<Parents>({ mother: [], father: [] })
  const [finalizedCase, setFinalizedCase] = useState<Partial<Case>>()
  const [existingSamples, setExistingSamples] = useState<Partial<Sample[]>>([])
  const [amountCases] = useState<number>(form.getFieldValue('cases')?.length)
  const userContext = useContext(UserContext)

  const finalizeCase = useCallback(
    async ({ errorFields }: any) => {
      let hasCaseError = false
      const localCase = form.getFieldValue('cases')[caseName]

      setHasSamples(localCase?.samples?.length > 0)
      errorFields?.map(({ name }) => {
        if (name[1] === caseName) hasCaseError = true
      })
      if (isBalsamic(analysisType)) {
        const counts = handleTumorCaseCount(localCase.samples)

        if (localCase.samples.length > 2) {
          hasCaseError = true
          setHasBalsamicSampleAmountError(true)
        } else {
          setHasTumorError(false)
          setHasBalsamicSampleAmountError(false)
        }

        if (counts.nonTumorCount > 1) {
          setHasNoneTumorError(true)
          hasCaseError = true
        } else if (counts.tumorCount > 1) {
          setHasTumorError(true)
          hasCaseError = true
        } else {
          setHasTumorError(false)
          setHasNoneTumorError(false)
        }
      }
      if (skipReceptionControl) {
        for (const sample of localCase.samples) {
          const validConcentration = await validateConcentrationLevel(
            sample?.concentration_ng_ul,
            sample.application,
            sample.source,
            userContext
          )
          if (!validConcentration) {
            hasCaseError = true
          }
          if (sample?.elution_buffer?.includes('Other')) {
            hasCaseError = true
          }
        }
      }

      if (isRNAFusion(analysisType) && localCase.samples.length > 1) {
        hasCaseError = true
        setHasRNAFusionSampleAmountError(true)
      } else setHasRNAFusionSampleAmountError(false)
      setIsFinalized(hasSamples ? !hasCaseError : false)
      setFinalizedCase(localCase)
    },
    [form, caseName, analysisType, skipReceptionControl, hasSamples, userContext]
  )

  const validateCase = useCallback(() => {
    form
      .validateFields()
      .then(() => finalizeCase([]))
      .catch((errors) => {
        finalizeCase(errors)
      })
  }, [finalizeCase, form])

  useEffect(() => {
    const localCase = form.getFieldValue('cases')[caseName]
    setIsExisting(!isNil(localCase?.internal_id))
    if (!isNil(localCase)) {
      setTimeout(() => {
        validateCase()
        setIsLoading(false)
      }, 2000)
    } else setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, validateCase])

  return (
    <Card
      key={caseName}
      bodyStyle={{
        backgroundColor: getBackgroundColor(caseName),
      }}
      style={{ margin: 3 }}
      id={`case ${caseName}`}
    >
      {isFinalized ? (
        <>
          <Row gutter={[16, 16]}>
            <Col flex="180px">
              <h2>{finalizedCase?.name}</h2>
            </Col>
            <Col flex="120px">
              <Tag
                color={
                  priorities.find((priority) => priority.value === finalizedCase?.priority)
                    ?.tagColor
                }
              >
                {priorities.find((priority) => priority.value === finalizedCase?.priority)?.text ||
                  finalizedCase?.priority}
              </Tag>
            </Col>
            <Col flex="auto">
              <div className={localStyles.savedCaseColumn} key={'finalized' + finalizedCase?.name}>
                {finalizedCase?.samples?.map((sample) => (
                  <>
                    {isMIPDNA(analysisType) && (
                      <Badge
                        dot
                        key={sample.name}
                        status={
                          carrierStatus.find((carrier) => carrier.status === sample.status)
                            ?.badgeColor || 'default'
                        }
                      >
                        <div>{sample.name}</div>
                      </Badge>
                    )}
                    {isBalsamic(analysisType) && (
                      <>
                        <div>
                          {sample.tumour && <Tag color={'geekblue'}>T</Tag>}
                          {sample.name}
                        </div>
                      </>
                    )}
                  </>
                ))}
              </div>
            </Col>
            <Col flex="130px">
              {finalizedCase?.panels?.map((panel) => (
                <Tag color="geekblue" key={panel}>
                  {panel}
                </Tag>
              ))}
            </Col>
            <Col flex="150px">
              <div className={localStyles.savedCaseColumn}>
                <DeletePopConfirm
                  itemType={'case'}
                  action={remove}
                  itemName={caseName}
                  triggerComponent={<Button icon={<DeleteTwoTone />}>Case</Button>}
                />
                <Button icon={<EditTwoTone />} onClick={() => setIsFinalized(false)}>
                  Edit
                </Button>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Spin tip="Validating case..." size="small" spinning={isLoading}>
          {isExisting && (
            <Alert
              message="Existing case. Changes can affect and delay ongoing analysis.
Adding samples is not allowed. Create a new case to combine existing samples with new ones."
              type="info"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}
          {hasTumorError && (
            <Alert
              message="A maximum of one tumor sample per case is allowed."
              type="error"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}
          {hasNoneTumorError && (
            <Alert
              message="Only one sample per case is permitted for non-tumor Balsamic analysis."
              type="error"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}
          {hasBalsamicSampleAmountError && (
            <Alert
              message="Maximum two samples per case allowed for Balsamic analysis."
              type="error"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}
          {hasRNAFusionSampleAmountError && (
            <Alert
              message="Maximum one samples per case allowed for RNA Fusion analysis."
              type="error"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}
          <div>
            <Row className={styles.formRow}>
              <Form.Item
                {...restField}
                label="Case name"
                name={[caseName, 'name']}
                required
                rules={[
                  {
                    required: true,
                    message: 'required',
                  },
                  {
                    message: 'Can include only numbers, letters and dash',
                    pattern: new RegExp(/^[a-zA-Z0-9-]*$/),
                  },
                ]}
              >
                <Input minLength={2} disabled={isExisting} style={{ width: 240 }} />
              </Form.Item>
              {(isMIPDNA(analysisType) || isTomte(analysisType)) && (
                <Form.Item
                  label="Gene panels"
                  name={[caseName, 'panels']}
                  rules={[
                    { required: true },
                    {
                      validator: (rule, userPanels) => {
                        return validatePanels(userPanels, options?.panels)
                      },
                    },
                  ]}
                >
                  <Select mode="multiple" allowClear style={{ width: 280 }}>
                    {options?.panels?.map((panel) => (
                      <Select.Option key={panel} value={panel}>
                        {panel}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label="Priority"
                name={[caseName, 'priority']}
                rules={[{ required: !isExisting }]}
                required={!isExisting}
              >
                <Select disabled={isExisting} style={{ width: 130 }}>
                  {priorities.map(({ value, text }) => (
                    <Select.Option key={value} value={value}>
                      {text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button style={{ marginRight: 5 }} type="primary" onClick={() => validateCase()}>
                  Save
                </Button>
              </Form.Item>
              <DeletePopConfirm
                itemType={'case'}
                action={remove}
                itemName={caseName}
                triggerComponent={<Button icon={<DeleteTwoTone />}>Case</Button>}
              />
            </Row>
            <Row className={styles.formRow}>
              <Form.Item label="Cohorts" name={[caseName, 'cohorts']}>
                <Select disabled={isExisting} mode="tags" style={{ width: 270 }} />
              </Form.Item>
              <Form.Item label="Synopsis" name={[caseName, 'synopsis']}>
                <Input disabled={isExisting} style={{ width: 312 }} />
              </Form.Item>
            </Row>
          </div>
          <Form.List name={[caseName, 'samples']}>
            {(samples, { add, remove }) => (
              <>
                <Button
                  disabled={isExisting}
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  type={'primary'}
                  style={{ marginBottom: 5, marginRight: 20 }}
                >
                  New Sample
                </Button>
                Add existing sample:{' '}
                <Select
                  disabled={isExisting}
                  showSearch
                  value={null}
                  placeholder="Search and select to add sample"
                  onSearch={(searchInput) => {
                    if (searchInput.length > 2) {
                      getExistingSamples(userContext, customer, searchInput)
                        .then(({ samples }) => setExistingSamples(() => samples))
                        .catch(() =>
                          ErrorNotification(
                            'Could not search for samples',
                            <div>
                              Something is wrong with the database. Open a ticket in the{' '}
                              <a href={`${supportSystemURL}scp/login.php`}>support system</a> if the
                              error persists
                            </div>
                          )
                        )
                    }
                  }}
                  onChange={(sampleName) =>
                    add(
                      buildExistingSample(
                        existingSamples.find((sample) => sample?.internal_id === sampleName)
                      )
                    )
                  }
                  optionFilterProp={'label'}
                  options={
                    existingSamples?.map((existingSample) => {
                      return {
                        label:
                          existingSample?.name + ' (' + existingSample?.customer.internal_id + ')',
                        value: existingSample?.internal_id,
                      }
                    }) || []
                  }
                />
                {!hasSamples && (
                  <Alert message="Add at least one sample to the case" type="error" />
                )}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                  {samples?.map(({ key, name }) => {
                    return (
                      <CaseSampleForm
                        key={name}
                        index={name}
                        remove={remove}
                        options={options}
                        form={form}
                        caseName={caseName}
                        parentsSamples={parentsSamples}
                        setParentSamples={setParentSamples}
                        analysisType={analysisType}
                        skipReceptionControl={skipReceptionControl}
                      />
                    )
                  })}
                </div>
              </>
            )}
          </Form.List>
        </Spin>
      )}
    </Card>
  )
}
