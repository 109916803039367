export const indexSequences = {
  '10X Genomics Dual Index kit TN Set A': [
    'SI_TN_A1 (AGTATCTGCA-TCGCTAGCGA)',
    'SI_TN_B1 (CATAGCATGA-GACCTGCCTG)',
    'SI_TN_C1 (AAGGGTTTAC-TACTCACGCG)',
    'SI_TN_D1 (AGCGCCTTGC-GTACGAAGTG)',
    'SI_TN_E1 (GTCATCCTAT-CTAGGGCAAA)',
    'SI_TN_F1 (CCTGGCTATA-CCCTCACAAA)',
    'SI_TN_G1 (TCATCGTTCT-AATTCGGGAA)',
    'SI_TN_H1 (CGTTCCACAT-GAGGGAGCCA)',
    'SI_TN_A2 (TCTATGAGTG-CAACCAACGA)',
    'SI_TN_B2 (TACTGCAATA-AGAGTCCATG)',
    'SI_TN_C2 (GCTTAAGCAA-GCCTACCGAA)',
    'SI_TN_D2 (CAGTAATACA-TTGAGCTGAG)',
    'SI_TN_E2 (CGACCCAGTG-TCGCACCAAC)',
    'SI_TN_F2 (ACTTGTTCGA-TGGATGGGTG)',
    'SI_TN_G2 (GCACTACTGA-CACTACGGTT)',
    'SI_TN_H2 (ATCTTGCAGC-CGAGTAAGGA)',
    'SI_TN_A3 (TTATTGACAC-GCGAACTGAT)',
    'SI_TN_B3 (TGGCTACCGG-CTGAGTCATT)',
    'SI_TN_C3 (GTGATCTGGG-ACTGTGTCGC)',
    'SI_TN_D3 (ATAGAACCAC-CTAACCTAAC)',
    'SI_TN_E3 (CATAGTTCGC-TTTCGTAACT)',
    'SI_TN_F3 (AACCATTAGT-GTATGTCGGG)',
    'SI_TN_G3 (GTTATCACGA-GCAACACCTC)',
    'SI_TN_H3 (CAGCTGTTAT-AGTACGTGAG)',
    'SI_TN_A4 (GAACAACCTT-GAACTGGTAC)',
    'SI_TN_B4 (ACGTTTGATT-TACTGAGAGA)',
    'SI_TN_C4 (ATGCAAGATC-ACGCCTCTGA)',
    'SI_TN_D4 (TTACAATCGT-CAGATTGTAC)',
    'SI_TN_E4 (TCACGTTGGG-CTTTGCTCCA)',
    'SI_TN_F4 (TTGCGGGACT-TGAGGATCGC)',
    'SI_TN_G4 (CAGGCGAATA-CCCTTTACCG)',
    'SI_TN_H4 (CTACGACTGA-CATCGCCCTC)',
    'SI_TN_A5 (TCTCGAATGT-ACGATCGCGA)',
    'SI_TN_B5 (TGGGTGCACA-CATGCATCAT)',
    'SI_TN_C5 (TCAAAGGGTT-GATTACTGAG)',
    'SI_TN_D5 (CTGCCTGGGT-GACCAATAGC)',
    'SI_TN_E5 (TAATCTTCGG-AGCCATCAAT)',
    'SI_TN_F5 (GAGCGAAAGC-TCCTTACCAA)',
    'SI_TN_G5 (TAAGTAGAAG-CGCGTTTCCT)',
    'SI_TN_H5 (GCTGGGATGC-GACTAACTGG)',
    'SI_TN_A6 (TTTGCTGGGT-CAAATTCCGG)',
    'SI_TN_B6 (ACGAGCGGAA-AACAACTAAG)',
    'SI_TN_C6 (TCAAGTAAAG-CAGTGCTGTT)',
    'SI_TN_D6 (GAAAGCGCGC-GAAACATATC)',
    'SI_TN_E6 (CCATCACCAC-GTGATCCCAA)',
    'SI_TN_F6 (AGCATCACAT-TCGTAGAGGA)',
    'SI_TN_G6 (CGAGGAGCAT-CAGTAAGTCT)',
    'SI_TN_H6 (CCGTCGCTGA-AGGTGTTAGT)',
    'SI_TN_A7 (TCCGAATAAA-ATGCTACCGC)',
    'SI_TN_B7 (CCATTGTAAG-TACGAATTGA)',
    'SI_TN_C7 (GCTGCTCCCA-CTACAGGGTC)',
    'SI_TN_D7 (TGTTCGCGAA-TCGTGAAATA)',
    'SI_TN_E7 (CCATTAGGCG-GCATTTCATC)',
    'SI_TN_F7 (ACCATTGCAC-ACAGTTAAGC)',
    'SI_TN_G7 (ATCACCGTTT-TGTCGAGGAG)',
    'SI_TN_H7 (AAGTTAGTAC-ACGCGGAATA)',
    'SI_TN_A8 (GTTTGAAAGT-GTACGCCATG)',
    'SI_TN_B8 (TAGTAGTTTG-TCATCGGGCG)',
    'SI_TN_C8 (ATCTGTAGTT-AGGCCCAATG)',
    'SI_TN_D8 (GCGTAACGAT-ATTCGTTCAA)',
    'SI_TN_E8 (CTGGTGATAA-AGGGACCTGG)',
    'SI_TN_F8 (GTTCTGGAAC-CGGGTACTGG)',
    'SI_TN_G8 (GACCGCCTTT-TTTAACTCGT)',
    'SI_TN_H8 (GACTCAGGGT-GAGACCCTTC)',
    'SI_TN_A9 (GCACGTGACA-AGGAAGTCTG)',
    'SI_TN_B9 (GATGGAAGGT-AAATTGAGCA)',
    'SI_TN_C9 (ATGGCGCAAA-CGATGCAAGC)',
    'SI_TN_D9 (TGTCAGTAAG-AGATGACATC)',
    'SI_TN_E9 (CGTTGGTCCG-GTAGCTGATA)',
    'SI_TN_F9 (CGGACGACCT-GTTGCGCCTC)',
    'SI_TN_G9 (TCCCGACCTG-AACCCACCAA)',
    'SI_TN_H9 (TGCACAAGCG-TGATGTTTGC)',
    'SI_TN_A10 (GTCGTTGCCT-AGAACTTCTT)',
    'SI_TN_B10 (ACAGGTTACG-AGATAAACAG)',
    'SI_TN_C10 (TAATGGGCAA-GCGCATAGGC)',
    'SI_TN_D10 (CTACGTAGGT-GTCCCACTTA)',
    'SI_TN_E10 (CTTCCTACTT-TCCTCCTGTA)',
    'SI_TN_F10 (TGGACCTTTG-TAAATCTCTG)',
    'SI_TN_G10 (ATTGTACAGT-TTGGTTACGT)',
    'SI_TN_H10 (TTCGCTTAAC-CCGCTCGTTA)',
    'SI_TN_A11 (TCGTACGATG-ACCCTCCCAT)',
    'SI_TN_B11 (AGTAGTTTGG-ATAGCATGCA)',
    'SI_TN_C11 (TAACTGTAGT-ATGACCGATA)',
    'SI_TN_D11 (CCTAGGCAAA-CAGAAATATC)',
    'SI_TN_E11 (AGACGCATCT-TTGTACGTGG)',
    'SI_TN_F11 (TCATATGTGA-AAAGTGTTCT)',
    'SI_TN_G11 (GACAATTGGG-ACATTTGGAA)',
    'SI_TN_H11 (AGACGACCGA-CCACAGAACA)',
    'SI_TN_A12 (CGCGAGTAGG-CCTGGTGACA)',
    'SI_TN_B12 (TCGCCATTTG-ACGGGCATGT)',
    'SI_TN_C12 (AGCCTTCTCT-CTGTCCGCGT)',
    'SI_TN_D12 (TTAACGGACG-CAAACGTCGC)',
    'SI_TN_E12 (TCGGGAGCTG-CCAGACTGCA)',
    'SI_TN_F12 (CGCCCTCATC-GCGTCTACGC)',
    'SI_TN_G12 (ATTGGCGCAA-TCAATTGCAA)',
    'SI_TN_H12 (AGTGGAGGGA-TGCATAGTTT)',
  ],
  '10X Genomics Dual Index kit TT Set A': [
    'SI_TT_A1 (GTAACATGCG-AGTGTTACCT)',
    'SI_TT_B1 (ACAGTAACTA-ACAGTTCGTT)',
    'SI_TT_C1 (TGCGCGGTTT-CAAGGATAAA)',
    'SI_TT_D1 (TGCAATGTTC-GCTTGTCGAA)',
    'SI_TT_E1 (TTATTCGAGG-CTGTCCTGCT)',
    'SI_TT_F1 (AAGATTGGAT-AGCGGGATTT)',
    'SI_TT_G1 (TGTAGTCATT-CTTGATCGTA)',
    'SI_TT_H1 (ACAATGTGAA-CGTACCGTTA)',
    'SI_TT_A2 (GTGGATCAAA-GCCAACCCTG)',
    'SI_TT_B2 (TCTACCATTT-CGGGAGAGTC)',
    'SI_TT_C2 (CAATCCCGAC-CCGAGTAGTA)',
    'SI_TT_D2 (TTAATACGCG-CACCTCGGGT)',
    'SI_TT_E2 (ATGGAGGGAG-ATAACCCATT)',
    'SI_TT_F2 (AAGGGCCGCA-CTGATTCCTC)',
    'SI_TT_G2 (CATGTGGGTT-GATTCCTTTA)',
    'SI_TT_H2 (TAGCATAGTG-CGGCTCTGTC)',
    'SI_TT_A3 (CACTACGAAA-TTAGACTGAT)',
    'SI_TT_B3 (CACGGTGAAT-GTTCGTCACA)',
    'SI_TT_C3 (ATGGCTTGTG-GAATGTTGTG)',
    'SI_TT_D3 (CCTTCTAGAG-AATACAACGA)',
    'SI_TT_E3 (ACCAGACAAC-AGGAACTAGG)',
    'SI_TT_F3 (GAGAGGATAT-TTGAAATGGG)',
    'SI_TT_G3 (ATGACGTCGC-AGGTCAGGAT)',
    'SI_TT_H3 (CCCGTTCTCG-GACGGATTGG)',
    'SI_TT_A4 (CTCTAGCGAG-TATCTTCATC)',
    'SI_TT_B4 (GTAGACGAAA-CTAGTGTGGT)',
    'SI_TT_C4 (TTCTCGATGA-TGTCGGGCAC)',
    'SI_TT_D4 (GCAGTATAGG-TTCCGTGCAC)',
    'SI_TT_E4 (AACCACGCAT-ATTCAGGTTA)',
    'SI_TT_F4 (CCCACCACAA-ACCTCCGCTT)',
    'SI_TT_G4 (GCGCTTATGG-GCCTGGCTAG)',
    'SI_TT_H4 (AGTTTCCTGG-TGCCACACAG)',
    'SI_TT_A5 (GTAGCCCTGT-GAGCATCTAT)',
    'SI_TT_B5 (TCGGCTCTAC-CCGATGGTCT)',
    'SI_TT_C5 (TCCGTTGGAT-ACGTTCTCGC)',
    'SI_TT_D5 (TGGTTCGGGT-GTGGCAGGAG)',
    'SI_TT_E5 (CGCGGTAGGT-CAGGATGTTG)',
    'SI_TT_F5 (CGGCTGGATG-TGATAAGCAC)',
    'SI_TT_G5 (ATAGGGCGAG-TGCATCGAGT)',
    'SI_TT_H5 (AGCAAGAAGC-TTGTGTTTCT)',
    'SI_TT_A6 (TAACGCGTGA-CCCTAACTTC)',
    'SI_TT_B6 (AATGCCATGA-TACGTAATGC)',
    'SI_TT_C6 (ACGACTACCA-ACGACCCTAA)',
    'SI_TT_D6 (CCCAGCTTCT-GACACCAAAC)',
    'SI_TT_E6 (TTGAGAGTCA-AACCTGGTAG)',
    'SI_TT_F6 (TTGCCCGTGC-GCGTGAGATT)',
    'SI_TT_G6 (GCGGGTAAGT-TAGCACTAAG)',
    'SI_TT_H6 (CCTATCCTCG-GAATACTAAC)',
    'SI_TT_A7 (TCCCAAGGGT-TACTACCTTT)',
    'SI_TT_B7 (GCCTTCGGTA-CCAACGATTT)',
    'SI_TT_C7 (CGCGCACTTA-CCTGTATTCT)',
    'SI_TT_D7 (CCTGTCAGGG-AGCCCGTAAC)',
    'SI_TT_E7 (GTCCTTCGGC-TCATGCACAG)',
    'SI_TT_F7 (AATGTATCCA-AATGAGCTTA)',
    'SI_TT_G7 (GTTTCACGAT-TTCGGCCAAA)',
    'SI_TT_H7 (ACCTCGAGCT-TGTGTTCGAT)',
    'SI_TT_A8 (CGAAGTATAC-GAACTTGGAG)',
    'SI_TT_B8 (GCACTGAGAA-TATGCGTGAA)',
    'SI_TT_C8 (GCTACAAAGC-CACGTGCCCT)',
    'SI_TT_D8 (CGCTGAAATC-AGGTGTCTGC)',
    'SI_TT_E8 (GAGCAAGGGC-ATTGACTTGG)',
    'SI_TT_F8 (CTCCTTTAGA-GACATAGCTC)',
    'SI_TT_G8 (TAAGCAACTG-CTATACTCAA)',
    'SI_TT_H8 (ATAAGGATAC-ATAGATAGGG)',
    'SI_TT_A9 (AAGTGGAGAG-TTCCTGTTAC)',
    'SI_TT_B9 (TATTGAGGCA-CAGGTAAGTG)',
    'SI_TT_C9 (TATCAGCCTA-GTTTCGTCCT)',
    'SI_TT_D9 (TGGTCCCAAG-CCTCTGGCGT)',
    'SI_TT_E9 (TGTCCCAACG-TCGATGTCCA)',
    'SI_TT_F9 (GTCCCATCAA-CGAACGTGAC)',
    'SI_TT_G9 (CCGGAGGAAG-TGCGGATGTT)',
    'SI_TT_H9 (AGAACTTAGA-CGAGTCCTTT)',
    'SI_TT_A10 (CGTGACATGC-ATGGTCTAAA)',
    'SI_TT_B10 (GCCCGATGGA-AATCGTCTAG)',
    'SI_TT_C10 (AGAATGGTTT-GAGGGTGGGA)',
    'SI_TT_D10 (ATGCGAATGG-ACAAGTGTCG)',
    'SI_TT_E10 (CACAATCCCA-ATATCCACAA)',
    'SI_TT_F10 (CCGGCAACTG-CGGTTTAACA)',
    'SI_TT_G10 (ACTTTACGTG-TGAACGCCCT)',
    'SI_TT_H10 (TTATCTAGGG-AAAGGCTCTA)',
    'SI_TT_A11 (CGGAACCCAA-GATTCGAGGA)',
    'SI_TT_B11 (TCTTACTTGC-TGACCTCTAG)',
    'SI_TT_C11 (ATGGGTGAAA-CTTGGGAATT)',
    'SI_TT_D11 (CGAATATTCG-CTGGAAGCAA)',
    'SI_TT_E11 (TCCGGGACAA-GTGAATGCCA)',
    'SI_TT_F11 (TTCACACCTT-TAGTGTACAC)',
    'SI_TT_G11 (GATAACCTGC-CATTAGAAAC)',
    'SI_TT_H11 (ACAATCGATC-TGACGGAATG)',
    'SI_TT_A12 (CACCGCACCA-GACTGTCAAT)',
    'SI_TT_B12 (CGTCAAGGGC-TAGGTCACTC)',
    'SI_TT_C12 (TCGTCAAGAT-GCAACTCAGG)',
    'SI_TT_D12 (GAATTGGTTA-ACTCTAGTAG)',
    'SI_TT_E12 (CGTCCACCTG-CATTCATGAC)',
    'SI_TT_F12 (GAGACGCACG-CTATGAACAT)',
    'SI_TT_G12 (CTTGCATAAA-ATCAGGGCTT)',
    'SI_TT_H12 (TGATGATTCA-GTAGGAGTCG)',
  ],
  'IDT DupSeq 10 bp Set B': [
    'A01 IDT_10nt_541 (ATTCCACACT-AACAAGACCA)',
    'B01 IDT_10nt_553 (TACTAGAGGA-AGACCGGTTC)',
    'C01 IDT_10nt_568 (TGTGAGCGAA-AACTCCGATC)',
    'D01 IDT_10nt_581 (GATGAAGGAT-AGCCTTATTC)',
    'E01 IDT_10nt_595 (AACAACCTCA-CTTAGTCCAA)',
    'F01 IDT_10nt_607 (AGATCTTCCA-AGATCAACAC)',
    'G01 IDT_10nt_620 (ACCGACGTGA-CATGACCGTT)',
    'H01 IDT_10nt_635 (AATCTCAGGC-GAGTTAGAGA)',
    'A02 IDT_10nt_542 (AGTTAGCTGG-CATTCTCATC)',
    'B02 IDT_10nt_554 (CGATGAGACT-GCACGACTAA)',
    'C02 IDT_10nt_569 (CTAGTCAGAA-CAGCATTCAA)',
    'D02 IDT_10nt_582 (CGATAGCAGG-AATGCTACGA)',
    'E02 IDT_10nt_596 (TATGGTGATG-GCTACTTGAA)',
    'F02 IDT_10nt_608 (ACCAGACGGT-GATGAAGACG)',
    'G02 IDT_10nt_621 (GTTGGACGGT-ACAGTGTCCT)',
    'H02 IDT_10nt_637 (CCGGTTCATG-CAAGACGTCT)',
    'A03 IDT_10nt_543 (TTGGCCATAC-TGCACTAGGC)',
    'B03 IDT_10nt_555 (TCGCTTAAGC-ACACGCCTAG)',
    'C03 IDT_10nt_570 (AAGACAGCTT-AGGTCAGCGA)',
    'D03 IDT_10nt_583 (TATCACTCTC-AGAACAAGGT)',
    'E03 IDT_10nt_597 (GCTGCTAACA-CACAAGAGCT)',
    'F03 IDT_10nt_609 (AGCTATCTTC-CTAGCGACAC)',
    'G03 IDT_10nt_622 (AGGTGGCTAC-CGTTACGTGG)',
    'H03 IDT_10nt_639 (AGAGGAATCG-GATAGTCTGG)',
    'A04 IDT_10nt_544 (TGCCACTGTA-TGACCTGCTG)',
    'B04 IDT_10nt_556 (GACAATAGAG-GTGTACACTA)',
    'C04 IDT_10nt_571 (AGACGGCATC-AGGTTACCTT)',
    'D04 IDT_10nt_584 (ACTGGTGTCG-ACAGGACTTG)',
    'E04 IDT_10nt_598 (TTAGGAGGAA-ACGCAAGTTC)',
    'F04 IDT_10nt_610 (ACAGAAGGTT-GATATCTCCA)',
    'G04 IDT_10nt_623 (GAGAGCTAAC-GCAAGTATTG)',
    'H04 IDT_10nt_641 (TTCAGGTCGT-AACCAACCAA)',
    'A05 IDT_10nt_545 (CTTGTCTTGC-CACATTGGTT)',
    'B05 IDT_10nt_557 (AACGACGCAT-CGTTGTAGTT)',
    'C05 IDT_10nt_573 (TTGGCCAGTT-CAGGTAGCAT)',
    'D05 IDT_10nt_586 (ATTGCGGCTG-TTGTAGGACA)',
    'E05 IDT_10nt_599 (AGAGACTTAC-CCAACTCTTA)',
    'F05 IDT_10nt_611 (GCTGCCGATA-ACGTAGGATA)',
    'G05 IDT_10nt_624 (GAATGTCCAA-TACGGTATTC)',
    'H05 IDT_10nt_642 (CTATACACCA-CCGTAGTTGG)',
    'A06 IDT_10nt_546 (ATACCTGTGA-AGCACAATGC)',
    'B06 IDT_10nt_558 (TCAGATTCAC-TCGCACTCCA)',
    'C06 IDT_10nt_574 (AGAGATGGTA-TTCCGTAAGC)',
    'D06 IDT_10nt_587 (ATGGAGACGG-GAACTCTCAG)',
    'E06 IDT_10nt_600 (GTGTACGTCG-CCTGCTAGGA)',
    'F06 IDT_10nt_612 (TCACCGTCAC-TAAGGAGTTG)',
    'G06 IDT_10nt_625 (TGTGAAGCTA-TGAATCGCGT)',
    'H06 IDT_10nt_643 (GTGTTATCTC-AGTCTGGTGT)',
    'A07 IDT_10nt_547 (AACACAGCCG-ACCAAGGTCT)',
    'B07 IDT_10nt_560 (CCGACTCCTT-CAAGGACAGT)',
    'C07 IDT_10nt_575 (GACGAACGTA-TGAAGGACGA)',
    'D07 IDT_10nt_588 (CCACTGTGAC-CATCTGTCCA)',
    'E07 IDT_10nt_601 (GAGCATCCAT-GTGGACGTGA)',
    'F07 IDT_10nt_613 (GTTATTAGGC-GTTCATCGAG)',
    'G07 IDT_10nt_627 (TTATCGCTGA-CCAATCCTAA)',
    'H07 IDT_10nt_644 (CGCTTAAGTA-GTGCAGTAAT)',
    'A08 IDT_10nt_548 (TTACGGAACA-GACAGAGTCA)',
    'B08 IDT_10nt_561 (CTATAGCGAG-CTAGTCGACT)',
    'C08 IDT_10nt_576 (ACGCGGACTT-CGAGGCAATA)',
    'D08 IDT_10nt_589 (AGAATCCTTC-ACACCTGGCA)',
    'E08 IDT_10nt_602 (CGGAACGAAG-AGGCGATGGT)',
    'F08 IDT_10nt_614 (TATCGTTGTG-TGCGGACACA)',
    'G08 IDT_10nt_628 (AACACAACGA-TTGGCTGCGT)',
    'H08 IDT_10nt_645 (CACGGTTGGT-ATTCAGCTAG)',
    'A09 IDT_10nt_549 (TCAGCACCGT-CCTATTCAGT)',
    'B09 IDT_10nt_563 (TCAAGTTCCT-CTAGAACTTG)',
    'C09 IDT_10nt_577 (AGACCTAGCT-GATACTTGTG)',
    'D09 IDT_10nt_590 (GCCTTACTTA-GTTAAGGTGC)',
    'E09 IDT_10nt_603 (GCCTAGGACT-AGCCGTTCTC)',
    'F09 IDT_10nt_615 (ATCTTCCTAG-TACACGCGCA)',
    'G09 IDT_10nt_630 (TTCCACCTGG-AGAAGCTCAT)',
    'H09 IDT_10nt_646 (TTATCTGTGC-ATACTGAGTG)',
    'A10 IDT_10nt_550 (ACTGTCAATC-GTTAAGGACG)',
    'B10 IDT_10nt_564 (TTCACACAGT-AACACTGAAG)',
    'C10 IDT_10nt_578 (CTGCGTACTC-TTGCCATCAG)',
    'D10 IDT_10nt_591 (CAGAACGTGG-CGGTGAAGTC)',
    'E10 IDT_10nt_604 (TGGATATGGC-ATACGCGCCA)',
    'F10 IDT_10nt_616 (TATAACTCCG-AGGACACATA)',
    'G10 IDT_10nt_631 (CTCTCATGCG-CAACCGACGT)',
    'H10 IDT_10nt_647 (CAACCGATTA-TAACTCAGCA)',
    'A11 IDT_10nt_551 (AATGTGGCGT-CTACCTGACA)',
    'B11 IDT_10nt_566 (GCTACTGTCG-ATGGAGCAAG)',
    'C11 IDT_10nt_579 (CGCAGAACTT-GACGTCGATA)',
    'D11 IDT_10nt_592 (CCAAGACACT-CCATGTCGGT)',
    'E11 IDT_10nt_605 (GTTCCACCGA-TAACAGCAGG)',
    'F11 IDT_10nt_617 (GACGTTCTCT-TAGCTCTACT)',
    'G11 IDT_10nt_632 (GCATACTCTA-TATCTCGCTA)',
    'H11 IDT_10nt_648 (GAGCTCCACA-TTCTGTTCTG)',
    'A12 IDT_10nt_552 (TCGTGGCTAG-TACTGCTGGC)',
    'B12 IDT_10nt_567 (AGGACTGAAC-CGTCGCTTCA)',
    'C12 IDT_10nt_580 (GCTGAGAGCT-CACCTATCAC)',
    'D12 IDT_10nt_593 (GAGCTTATGA-AATGACTGGT)',
    'E12 IDT_10nt_606 (AATGGTACTG-TAACTGCTGT)',
    'F12 IDT_10nt_618 (CAGCCTTGAG-ATGCTACTCA)',
    'G12 IDT_10nt_634 (TCACCAGGAC-GAGACAAGGA)',
    'H12 IDT_10nt_649 (TAATCTACGG-TAAGTGCCAG)',
  ],
  'IDT DupSeq 10 bp Set F': [
    'A01 IDT_10nt_1402 (ACCTATACCT-AATGACCTGA)',
    'B01 IDT_10nt_1418 (TCAATGGCGG-TCACCGTATA)',
    'C01 IDT_10nt_1432 (ACCGCAATTA-TTGGTCAATC)',
    'D01 IDT_10nt_1447 (CATTGAAGGA-ACGAACCATT)',
    'E01 IDT_10nt_1461 (GCTCACGTTC-CTGAAGCTTA)',
    'F01 IDT_10nt_1474 (CTCAGGTGTC-CAGTCTTCGG)',
    'G01 IDT_10nt_1487 (TGCGTAAGGT-CTACCACGAA)',
    'H01 IDT_10nt_1501 (TCTCGTATGC-AGTACGTGAA)',
    'A02 IDT_10nt_1403 (CAAGGCATGC-TTGTACGGCT)',
    'B02 IDT_10nt_1419 (CAGATTAGTC-ACATCCTCAC)',
    'C02 IDT_10nt_1433 (CACCAAGAAC-AGTAACCACT)',
    'D02 IDT_10nt_1448 (AGGATCAAGA-TCGGCTATGA)',
    'E02 IDT_10nt_1462 (TGCCAACCAC-TATCAGTGCA)',
    'F02 IDT_10nt_1475 (AGGCGAATTC-TAGTGAATCG)',
    'G02 IDT_10nt_1488 (ACCGGAGATG-GCAGAGCACA)',
    'H02 IDT_10nt_1503 (ACCTCACATA-TACAGCCTTG)',
    'A03 IDT_10nt_1404 (CGGTAATGAA-GATCGCCTCA)',
    'B03 IDT_10nt_1421 (ACCTCTGACA-TGTTACCTCA)',
    'C03 IDT_10nt_1434 (TCGAGCATAG-CAGATCTAGG)',
    'D03 IDT_10nt_1449 (TCTCAGCGGT-GCGAGCCATA)',
    'E03 IDT_10nt_1463 (TTAGTGGTCA-TGGATGGATA)',
    'F03 IDT_10nt_1477 (AGATTGTGCG-TTCCAGAGAA)',
    'G03 IDT_10nt_1491 (GAGGACGATC-AGCTACCAAT)',
    'H03 IDT_10nt_1504 (AGCAGCACAA-CCTACTTCTA)',
    'A04 IDT_10nt_1406 (GTTACTGGAC-GCCGAATATC)',
    'B04 IDT_10nt_1422 (AACTCATGTC-GAAGGCCTAA)',
    'C04 IDT_10nt_1435 (ATGCTCTCTT-ATCCAGGTTA)',
    'D04 IDT_10nt_1450 (CTTAACAAGG-CATGTTCTGT)',
    'E04 IDT_10nt_1464 (ACTGTATCAC-AGAGTAGTCA)',
    'F04 IDT_10nt_1478 (CTCTCCAACG-TATGGAAGTG)',
    'G04 IDT_10nt_1492 (TAGGTGATCT-ATAGAACACG)',
    'H04 IDT_10nt_1506 (TAAGTCTGGC-CTCCAGCTTC)',
    'A05 IDT_10nt_1408 (GCAGGTAATA-GCTCTGAAGG)',
    'B05 IDT_10nt_1423 (TGGAATACCA-TACGAATCGA)',
    'C05 IDT_10nt_1437 (CGACTTATGC-AACCTCAGCG)',
    'D05 IDT_10nt_1452 (TTACGTGCCG-CAACTCGTAA)',
    'E05 IDT_10nt_1465 (ACGGAGTTGA-GTTCCGACGT)',
    'F05 IDT_10nt_1479 (ACTTAGCCAT-GATCGTGCTC)',
    'G05 IDT_10nt_1493 (CACCGGTGAT-GCCAATACAA)',
    'H05 IDT_10nt_1507 (AGGAATTGTC-GTCAACTCGG)',
    'A06 IDT_10nt_1410 (GTGTAGATGT-GTTCATGACT)',
    'B06 IDT_10nt_1424 (TGTTCGCGAC-AGCTTAGCTC)',
    'C06 IDT_10nt_1438 (CTCGTTACAA-AATGCACCAA)',
    'D06 IDT_10nt_1453 (TGATGGAATC-CCTTCATAGG)',
    'E06 IDT_10nt_1466 (GTAACTTAGC-ACGCGAGTCA)',
    'F06 IDT_10nt_1480 (AACACCGCAA-CCACATGACG)',
    'G06 IDT_10nt_1494 (GTAACCATCA-CCATAGTCGC)',
    'H06 IDT_10nt_1508 (AGGCAACGGA-ATCCAAGTAG)',
    'A07 IDT_10nt_1411 (TGATTGGAGA-CTTGGCCTCT)',
    'B07 IDT_10nt_1425 (GATGAGCGGT-TGTTAGAGGT)',
    'C07 IDT_10nt_1439 (AATCATGGAG-GCCACTTCCT)',
    'D07 IDT_10nt_1454 (ACGGAAGCAA-CGGTTGCAGT)',
    'E07 IDT_10nt_1467 (ACTTGCCTGT-CCAAGAAGAT)',
    'F07 IDT_10nt_1481 (CCAGTCACAC-CACGACTAGC)',
    'G07 IDT_10nt_1495 (CTCTGAGGTA-CCATATACGA)',
    'H07 IDT_10nt_1509 (CTAACGAGGT-CGAAGCCAAG)',
    'A08 IDT_10nt_1412 (CAAGTGCGTC-TCATCGGAAT)',
    'B08 IDT_10nt_1426 (AATGTCGGTT-GTTCCTCATT)',
    'C08 IDT_10nt_1440 (AATGGCTCGG-CTGGTATTAG)',
    'D08 IDT_10nt_1456 (GTCTGATCGT-GCTTGAGACG)',
    'E08 IDT_10nt_1468 (TCGTCCTGAC-CTGTGAAGAA)',
    'F08 IDT_10nt_1482 (CCTCCAACCA-GCCTGTACTG)',
    'G08 IDT_10nt_1496 (TCGGTACCTC-GTGGTGACTG)',
    'H08 IDT_10nt_1510 (GATTGAGTGT-ACACAACATG)',
    'A09 IDT_10nt_1413 (AGAAGATGCA-ACCTTAGCCG)',
    'B09 IDT_10nt_1427 (TTCTCAAGTG-TGGCTTATCA)',
    'C09 IDT_10nt_1442 (GCATATCACT-ATCTCCAGAT)',
    'D09 IDT_10nt_1457 (CTACGTCTAG-GTGGACCTTG)',
    'E09 IDT_10nt_1469 (AACAGTAGCA-TACGCAACGG)',
    'F09 IDT_10nt_1483 (CGTGTTAGAG-TCTTACCGAA)',
    'G09 IDT_10nt_1497 (CCTTGGTGCA-ACGTGTAGTA)',
    'H09 IDT_10nt_1511 (GACAGCAGCT-ATGACTCCTC)',
    'A10 IDT_10nt_1415 (ACTCACCGAG-CTCCTGCGTA)',
    'B10 IDT_10nt_1428 (CCATTGTCAA-ACTTAAGGAC)',
    'C10 IDT_10nt_1443 (CACGTTCGTG-AGAAGTAGGA)',
    'D10 IDT_10nt_1458 (GTTGTCGTAT-GAGGAGTATG)',
    'E10 IDT_10nt_1470 (TAGTGATAGC-GCAGCATAAT)',
    'F10 IDT_10nt_1484 (CACTCTGAAC-GTGATAAGCT)',
    'G10 IDT_10nt_1498 (GCGCAATTGT-GCATCGGTTC)',
    'H10 IDT_10nt_1512 (GTAGGAACCG-ATGGTCAGTA)',
    'A11 IDT_10nt_1416 (ATTAGGCACG-ACAAGCAATG)',
    'B11 IDT_10nt_1429 (TCCTCGGATA-CGCGGAGATT)',
    'C11 IDT_10nt_1444 (AGATCGAAGT-TGGACGTGCT)',
    'D11 IDT_10nt_1459 (GAACTACACT-GCTTACACTT)',
    'E11 IDT_10nt_1472 (CGAACTTGAG-TCCACTGCGT)',
    'F11 IDT_10nt_1485 (GTGGAACAAT-AGACAGGAAT)',
    'G11 IDT_10nt_1499 (ATTCTTCCGG-GAACGGCCAT)',
    'H11 IDT_10nt_1513 (ATGAGGTAAC-GCGTACGACA)',
    'A12 IDT_10nt_1417 (GAAGTATCGT-GCTGCTCTAT)',
    'B12 IDT_10nt_1430 (AAGACGACGC-TTATGGTGGT)',
    'C12 IDT_10nt_1445 (CTCAGGACAT-AGGCCTCCAA)',
    'D12 IDT_10nt_1460 (AACCGAACCT-GTCGATGATT)',
    'E12 IDT_10nt_1473 (GTGTACCGTT-TTGAGCCTGG)',
    'F12 IDT_10nt_1486 (CTAAGTTGGT-AGATTCAGCA)',
    'G12 IDT_10nt_1500 (CCATGCAGTT-GAGACAGAGC)',
    'H12 IDT_10nt_1514 (TTACTGCCTT-GCTCGGAACT)',
  ],
  'IDT xGen UDI Adapters': [
    'A1 xGen UDI Index 1 (CTGATCGT-ATATGCGC)',
    'B1 xGen UDI Index 2 (ACTCTCGA-TGGTACAG)',
    'C1 xGen UDI Index 3 (TGAGCTAG-AACCGTTC)',
    'D1 xGen UDI Index 4 (GAGACGAT-TAACCGGT)',
    'E1 xGen UDI Index 5 (CTTGTCGA-GAACATCG)',
    'F1 xGen UDI Index 6 (TTCCAAGG-CCTTGTAG)',
    'G1 xGen UDI Index 7 (CGCATGAT-TCAGGCTT)',
    'H1 xGen UDI Index 8 (ACGGAACA-GTTCTCGT)',
    'A2 xGen UDI Index 9 (CGGCTAAT-AGAACGAG)',
    'B2 xGen UDI Index 10 (ATCGATCG-TGCTTCCA)',
    'C2 xGen UDI Index 11 (GCAAGATC-CTTCGACT)',
    'D2 xGen UDI Index 12 (GCTATCCT-CACCTGTT)',
    'E2 xGen UDI Index 13 (TACGCTAC-ATCACACG)',
    'F2 xGen UDI Index 14 (TGGACTCT-CCGTAAGA)',
    'G2 xGen UDI Index 15 (AGAGTAGC-TACGCCTT)',
    'H2 xGen UDI Index 16 (ATCCAGAG-CGACGTTA)',
    'A3 xGen UDI Index 17 (GACGATCT-ATGCACGA)',
    'B3 xGen UDI Index 18 (AACTGAGC-CCTGATTG)',
    'C3 xGen UDI Index 19 (CTTAGGAC-GTAGGAGT)',
    'D3 xGen UDI Index 20 (GTGCCATA-ACTAGGAG)',
    'E3 xGen UDI Index 21 (GAATCCGA-CACTAGCT)',
    'F3 xGen UDI Index 22 (TCGCTGTT-ACGACTTG)',
    'G3 xGen UDI Index 23 (TTCGTTGG-CGTGTGTA)',
    'H3 xGen UDI Index 24 (AAGCACTG-GTTGACCT)',
    'A4 xGen UDI Index 25 (CCTTGATC-ACTCCATC)',
    'B4 xGen UDI Index 26 (GTCGAAGA-CAATGTGG)',
    'C4 xGen UDI Index 27 (ACCACGAT-TTGCAGAC)',
    'D4 xGen UDI Index 28 (GATTACCG-CAGTCCAA)',
    'E4 xGen UDI Index 29 (GCACAACT-ACGTTCAG)',
    'F4 xGen UDI Index 30 (GCGTCATT-AACGTCTG)',
    'G4 xGen UDI Index 31 (ATCCGGTA-TATCGGTC)',
    'H4 xGen UDI Index 32 (CGTTGCAA-CGCTCTAT)',
    'A5 xGen UDI Index 33 (GTGAAGTG-GATTGCTC)',
    'B5 xGen UDI Index 34 (CATGGCTA-GATGTGTG)',
    'C5 xGen UDI Index 35 (ATGCCTGT-CGCAATCT)',
    'D5 xGen UDI Index 36 (CAACACCT-TGGTAGCT)',
    'E5 xGen UDI Index 37 (TGTGACTG-GATAGGCT)',
    'F5 xGen UDI Index 38 (GTCATCGA-AGTGGATC)',
    'G5 xGen UDI Index 39 (AGCACTTC-TTGGACGT)',
    'H5 xGen UDI Index 40 (GAAGGAAG-ATGACGTC)',
    'A6 xGen UDI Index 41 (GTTGTTCG-GAAGTTGG)',
    'B6 xGen UDI Index 42 (CGGTTGTT-CATACCAC)',
    'C6 xGen UDI Index 43 (ACTGAGGT-CTGTTGAC)',
    'D6 xGen UDI Index 44 (TGAAGACG-TGGCATGT)',
    'E6 xGen UDI Index 45 (GTTACGCA-ATCGCCAT)',
    'F6 xGen UDI Index 46 (AGCGTGTT-TTGCGAAG)',
    'G6 xGen UDI Index 47 (GATCGAGT-AGTTCGTC)',
    'H6 xGen UDI Index 48 (ACAGCTCA-GAGCAGTA)',
    'A7 xGen UDI Index 49 (GAGCAGTA-ACAGCTCA)',
    'B7 xGen UDI Index 50 (AGTTCGTC-GATCGAGT)',
    'C7 xGen UDI Index 51 (TTGCGAAG-AGCGTGTT)',
    'D7 xGen UDI Index 52 (ATCGCCAT-GTTACGCA)',
    'E7 xGen UDI Index 53 (TGGCATGT-TGAAGACG)',
    'F7 xGen UDI Index 54 (CTGTTGAC-ACTGAGGT)',
    'G7 xGen UDI Index 55 (CATACCAC-CGGTTGTT)',
    'H7 xGen UDI Index 56 (GAAGTTGG-GTTGTTCG)',
    'A8 xGen UDI Index 57 (ATGACGTC-GAAGGAAG)',
    'B8 xGen UDI Index 58 (TTGGACGT-AGCACTTC)',
    'C8 xGen UDI Index 59 (AGTGGATC-GTCATCGA)',
    'D8 xGen UDI Index 60 (GATAGGCT-TGTGACTG)',
    'E8 xGen UDI Index 61 (TGGTAGCT-CAACACCT)',
    'F8 xGen UDI Index 62 (CGCAATCT-ATGCCTGT)',
    'G8 xGen UDI Index 63 (GATGTGTG-CATGGCTA)',
    'H8 xGen UDI Index 64 (GATTGCTC-GTGAAGTG)',
    'A9 xGen UDI Index 65 (CGCTCTAT-CGTTGCAA)',
    'B9 xGen UDI Index 66 (TATCGGTC-ATCCGGTA)',
    'C9 xGen UDI Index 67 (AACGTCTG-GCGTCATT)',
    'D9 xGen UDI Index 68 (ACGTTCAG-GCACAACT)',
    'E9 xGen UDI Index 69 (CAGTCCAA-GATTACCG)',
    'F9 xGen UDI Index 70 (TTGCAGAC-ACCACGAT)',
    'G9 xGen UDI Index 71 (CAATGTGG-GTCGAAGA)',
    'H9 xGen UDI Index 72 (ACTCCATC-CCTTGATC)',
    'A10 xGen UDI Index 73 (GTTGACCT-AAGCACTG)',
    'B10 xGen UDI Index 74 (CGTGTGTA-TTCGTTGG)',
    'C10 xGen UDI Index 75 (ACGACTTG-TCGCTGTT)',
    'D10 xGen UDI Index 76 (CACTAGCT-GAATCCGA)',
    'E10 xGen UDI Index 77 (ACTAGGAG-GTGCCATA)',
    'F10 xGen UDI Index 78 (GTAGGAGT-CTTAGGAC)',
    'G10 xGen UDI Index 79 (CCTGATTG-AACTGAGC)',
    'H10 xGen UDI Index 80 (ATGCACGA-GACGATCT)',
    'A11 xGen UDI Index 81 (CGACGTTA-ATCCAGAG)',
    'B11 xGen UDI Index 82 (TACGCCTT-AGAGTAGC)',
    'C11 xGen UDI Index 83 (CCGTAAGA-TGGACTCT)',
    'D11 xGen UDI Index 84 (ATCACACG-TACGCTAC)',
    'E11 xGen UDI Index 85 (CACCTGTT-GCTATCCT)',
    'F11 xGen UDI Index 86 (CTTCGACT-GCAAGATC)',
    'G11 xGen UDI Index 87 (TGCTTCCA-ATCGATCG)',
    'H11 xGen UDI Index 88 (AGAACGAG-CGGCTAAT)',
    'A12 xGen UDI Index 89 (GTTCTCGT-ACGGAACA)',
    'B12 xGen UDI Index 90 (TCAGGCTT-CGCATGAT)',
    'C12 xGen UDI Index 91 (CCTTGTAG-TTCCAAGG)',
    'D12 xGen UDI Index 92 (GAACATCG-CTTGTCGA)',
    'E12 xGen UDI Index 93 (TAACCGGT-GAGACGAT)',
    'F12 xGen UDI Index 94 (AACCGTTC-TGAGCTAG)',
    'G12 xGen UDI Index 95 (TGGTACAG-ACTCTCGA)',
    'H12 xGen UDI Index 96 (ATATGCGC-CTGATCGT)',
  ],
  'KAPA UDI NIPT': [
    'A01 UDI0001 (GTAACATC-CAGCGATT)',
    'B01 UDI0002 (AGGTAAGG-CACGATTC)',
    'C01 UDI0003 (ACAGGTAT-GCCACCAT)',
    'D01 UDI0004 (AATGTTCT-AGTCACCT)',
    'E01 UDI0005 (TCTGCAAG-TTCACCTT)',
    'F01 UDI0006 (CAGCGGTA-TGACTTGG)',
    'G01 UDI0007 (CGCCTTCC-GCGGACTT)',
    'H01 UDI0008 (CAATAGTC-CAGCTCAC)',
    'A02 UDI0009 (ATTATCAA-CGACTCTC)',
    'B02 UDI0010 (CCAACATT-GCTCTCTT)',
    'C02 UDI0011 (GCCTAGCC-TTGGTCTG)',
    'D02 UDI0012 (GACCAGGA-CTGGCTAT)',
    'E02 UDI0013 (CTGTAATC-AATTGCTT)',
    'F02 UDI0014 (ACTAAGAC-TTCCAGCT)',
    'G02 UDI0015 (TCGCTAGA-AGTACTGC)',
    'H02 UDI0016 (AACGCATT-GCAGGTTG)',
    'A03 UDI0017 (TGCTGCTG-GTCCTCAT)',
    'B03 UDI0018 (TATCTGCC-CCAACGCT)',
    'C03 UDI0019 (ATTCCTCT-GCGATATT)',
    'D03 UDI0020 (CAACTCTC-ATCTTCTC)',
    'E03 UDI0021 (GCCGTCGA-TTAATCAC)',
    'F03 UDI0022 (TATCCAGG-TCCACTTC)',
    'G03 UDI0023 (TAAGCACA-GACATTAA)',
    'H03 UDI0024 (GTCCACAG-CGCGAATA)',
    'A04 UDI0025 (ACACGATC-AATACCAT)',
    'B04 UDI0026 (GTATAACA-TGCTTCAC)',
    'C04 UDI0027 (TGTCGGAT-TCAGGCTT)',
    'D04 UDI0028 (AGGATCTA-GAACTTCG)',
    'E04 UDI0029 (AGCAATTC-CTGCTCCT)',
    'F04 UDI0030 (CCTATGCC-CAAGCTTA)',
    'G04 UDI0031 (AAGGATGT-CACTTCAT)',
    'H04 UDI0032 (TTGAGCCT-TCATTCGA)',
    'A05 UDI0033 (CACATCCT-GCTGCACT)',
    'B05 UDI0034 (TTCGCTGA-CGCATATT)',
    'C05 UDI0035 (CATGCTTA-ATGAATTA)',
    'D05 UDI0036 (AAGTAGAG-ATCGACTG)',
    'E05 UDI0037 (CATAGCGA-GACGGTTA)',
    'F05 UDI0038 (AGTTGCTT-TAGCATTG)',
    'G05 UDI0039 (GCACATCT-AACCTCTT)',
    'H05 UDI0040 (CCTACCAT-GCTTCCTA)',
    'A06 UDI0041 (TGCTCGAC-ATCCTTAA)',
    'B06 UDI0042 (CCAGTTAG-CCTGTCAT)',
    'C06 UDI0043 (TGTTCCGA-TTAGCCAG)',
    'D06 UDI0044 (GGTCCAGA-CGGTTCTT)',
    'E06 UDI0045 (TCGGAATG-CTACATTG)',
    'F06 UDI0046 (ATAGCGTC-TACTCCAG)',
    'G06 UDI0047 (AACTTGAC-GCTAGCAG)',
    'H06 UDI0048 (ATTCTAGG-TTCTTGGC)',
    'A07 UDI0049 (TTGAATAG-TCCATAAC)',
    'B07 UDI0050 (TCTGGCGA-AATTCAAC)',
    'C07 UDI0051 (TAATGAAC-CTTGGCTT)',
    'D07 UDI0052 (ATTATGTT-CTGTATTC)',
    'E07 UDI0053 (ATTGTCTG-TTCACAGA)',
    'F07 UDI0054 (GAAGAAGT-CTATTAGC)',
    'G07 UDI0055 (GACAGTAA-GCGATTAC)',
    'H07 UDI0056 (CCTTCGCA-CATCACTT)',
    'A08 UDI0057 (CATGATCG-TACTCTCC)',
    'B08 UDI0058 (TCCTTGGT-GAATCGAC)',
    'C08 UDI0059 (GTCATCTA-TCCAACCA)',
    'D08 UDI0060 (GAACCTAG-CTGGTATT)',
    'E08 UDI0061 (CAGCAAGG-CCTCTAAC)',
    'F08 UDI0062 (CGTTACCA-GAACGCTA)',
    'G08 UDI0063 (TCCAGCAA-AATTGGCC)',
    'H08 UDI0064 (CAGGAGCC-GTCCAATC)',
    'A09 UDI0065 (TTACGCAC-GACCATCT)',
    'B09 UDI0066 (AGGTTATC-ATCATACC)',
    'C09 UDI0067 (TCGCCTTG-GCTGATTC)',
    'D09 UDI0068 (CCAGAGCT-CGAACTTC)',
    'E09 UDI0069 (TACTTAGC-AGGTACCA)',
    'F09 UDI0070 (GTCTGATG-ATATCCGA)',
    'G09 UDI0071 (TCTCGGTC-CTGACATC)',
    'H09 UDI0072 (AAGACACT-TGACAGCA)',
    'A10 UDI0073 (CTACCAGG-CAACTGAT)',
    'B10 UDI0074 (ACTGTATC-TGCTATTA)',
    'C10 UDI0075 (CTGTGGCG-CACTAGCC)',
    'D10 UDI0076 (TGTAATCA-AATCTCCA)',
    'E10 UDI0077 (TTATATCT-GTCTGCAC)',
    'F10 UDI0078 (GCCGCAAC-TCATGTCT)',
    'G10 UDI0079 (TGTAACTC-CGACAGTT)',
    'H10 UDI0080 (CTGCGGAT-GGTTATCT)',
    'A11 UDI0081 (GACCGTTG-CCATCACA)',
    'B11 UDI0082 (AACAATGG-TAGTTAGC)',
    'C11 UDI0083 (AGGTGCGA-CTTCTGGC)',
    'D11 UDI0084 (AGGTCGCA-GCACAATT)',
    'E11 UDI0085 (ACCAACTG-GGCAATAC)',
    'F11 UDI0086 (TGCAAGTA-CCAACTAA)',
    'G11 UDI0087 (GACCTAAC-GCTCACCA)',
    'H11 UDI0088 (AGCATGGA-AGCGCTAA)',
    'A12 UDI0089 (ACAGTTGA-GCTCCGAT)',
    'B12 UDI0090 (TTGTCTAT-CTTGAATC)',
    'C12 UDI0091 (CGCTATGT-TCCGCATA)',
    'D12 UDI0092 (TTAATCAG-CCAATCTG)',
    'E12 UDI0093 (CTATGCGT-GAATATCA)',
    'F12 UDI0094 (GATATCCA-GGATTAAC)',
    'G12 UDI0095 (GAAGGAAG-CATCCTGG)',
    'H12 UDI0096 (CTAACTCG-TATGGTTC)',
  ],
  'NEXTflex® Unique Dual Index Barcodes 1 - 96': [
    'UDI1 (AATCGTTA-AATAACGT)',
    'UDI2 (GTCTACAT-TTCTTGAA)',
    'UDI3 (CGCTGCTC-GGCAGATC)',
    'UDI4 (GATCAACA-CTATGTTA)',
    'UDI5 (CGAAGGAC-GTTGACGC)',
    'UDI6 (GATGCCGG-ATCTACGA)',
    'UDI7 (CTACGAAG-CTCGACAG)',
    'UDI8 (GATGCGTC-GAGGCTGC)',
    'UDI9 (CTACGGCA-CCTCGTAG)',
    'UDI10 (GATTCCTT-CATAGGCA)',
    'UDI11 (CTACTCGA-AGATGAAC)',
    'UDI12 (GATTCGAG-CCGAGTAT)',
    'UDI13 (AATCGGCG-AATATTGA)',
    'UDI14 (TTCGCCGA-GTATACCG)',
    'UDI15 (CTGGCCTC-GATCCAAC)',
    'UDI16 (GAACTTAT-AGATACGC)',
    'UDI17 (CGTATTGG-GGTATCTT)',
    'UDI18 (GAAGCACA-CCTCTGGC)',
    'UDI19 (CTTAATAC-CCATTGTG)',
    'UDI20 (GAAGTCTT-ACTACGGT)',
    'UDI21 (GAAGAGGC-AAGTGCTA)',
    'UDI22 (CGGATAAC-GCCGAACG)',
    'UDI23 (GAATCTGG-TGTCCACG)',
    'UDI24 (CTGATTGA-GACACACT)',
    'UDI25 (AATCCGTT-AATATGCT)',
    'UDI26 (TGCGTACA-TTCTCATA)',
    'UDI27 (GAATCAAT-TCTGTGAT)',
    'UDI28 (TGAGTCAG-CCGAACTT)',
    'UDI29 (GAATGCTC-GTCTAACA)',
    'UDI30 (GAATATCC-GACGCCAT)',
    'UDI31 (CTTATGAA-GCCAATGT)',
    'UDI32 (TCGGCACC-CCAACGTC)',
    'UDI33 (AAGAAGCG-GTAGATAA)',
    'UDI34 (CTCACGAT-CTTACGGC)',
    'UDI35 (TCGGTCGA-CCAAGTGC)',
    'UDI36 (TCGGTAAG-CTAACTCA)',
    'UDI37 (AAGATACA-AATATCTG)',
    'UDI38 (GTCGCTGT-TTATATCA)',
    'UDI39 (TCGGATGT-CTGCGGAT)',
    'UDI40 (CGAGCCGG-GCGGCTTG)',
    'UDI41 (CGATTATC-GAGTTGAT)',
    'UDI42 (TCGAAGCT-GCACTGAG)',
    'UDI43 (CTATCATT-GACCACCT)',
    'UDI44 (CGCGCCAA-TGGCTAGG)',
    'UDI45 (CGAACGGA-CCTACCGG)',
    'UDI46 (CTACTGAC-GGAGGATG)',
    'UDI47 (TCTTAAGT-CGCTGAAT)',
    'UDI48 (TTAGAGTC-TGTGACGA)',
    'UDI49 (AAGACGAA-AATAGATT)',
    'UDI50 (TTATTATG-TTAGCGCA)',
    'UDI51 (CGCTATTA-GCGGCCGT)',
    'UDI52 (TCTATCAG-CAGTAACC)',
    'UDI53 (CGGTGGTA-GCCTAGTA)',
    'UDI54 (TCACCAAT-CACGGCGC)',
    'UDI55 (CTGGAAGC-GGTGCAGA)',
    'UDI56 (CGTAAGAG-TCGCTGAC)',
    'UDI57 (AAGAGAGC-CAGCCAGT)',
    'UDI58 (TCAACGAG-CGTCAACC)',
    'UDI59 (TGCGAGAC-GCCGGCGA)',
    'UDI60 (CCTGGTGT-GCCTCCGG)',
    'UDI61 (AAGTAAGT-AATAGTCC)',
    'UDI62 (TGACTGAA-TTAGACGT)',
    'UDI63 (AAGACTGT-GTGGACTA)',
    'UDI64 (CAATGATG-CACGGACG)',
    'UDI65 (CACAGTAA-CACTAGAG)',
    'UDI66 (TGGTCATT-GCAGATGG)',
    'UDI67 (CAACCGTG-CTCTCACG)',
    'UDI68 (TGGTGCAC-GGAATCAC)',
    'UDI69 (CCACAATG-CGTTGACG)',
    'UDI70 (TGTGTGCC-CATCAGGT)',
    'UDI71 (CACCACGG-CGTTGTAA)',
    'UDI72 (TGTGTTAA-GGCACGGT)',
    'UDI73 (AAGTTATC-AATAGCAA)',
    'UDI74 (GTACAGCT-TGATCGGT)',
    'UDI75 (CAACTGCT-AGTAGTAT)',
    'UDI76 (CATGATGA-GTTAGAGG)',
    'UDI77 (TGACTACT-CCTTACAG)',
    'UDI78 (CAGAAGAT-GTACATTG)',
    'UDI79 (TGAGGCGC-GGAGACCA)',
    'UDI80 (CAGGTTCC-CGAACACC)',
    'UDI81 (TGAACAGG-GAGAACAA)',
    'UDI82 (CAGTGTGG-TGTGAATC)',
    'UDI83 (TTCCACCA-GGTTAAGG)',
    'UDI84 (CCGCTGTT-AGACCGCA)',
    'UDI85 (AAGTTGGA-AATACAGG)',
    'UDI86 (GGACAACG-TGATGGCC)',
    'UDI87 (TTCGAACC-TGTCACCT)',
    'UDI88 (CAGACCAC-GCTTCGGC)',
    'UDI89 (TTCTGGTG-CCAGTGGT)',
    'UDI90 (CAATCGAA-GCACACGC)',
    'UDI91 (AAGTACAG-GTCACGTC)',
    'UDI92 (CCGTGCCA-GCAGCTCC)',
    'UDI93 (CATTGCAC-CATGCAGC)',
    'UDI94 (TTACCTGG-ACGATTGC)',
    'UDI95 (CTGCAACG-GACATTCG)',
    'UDI96 (TACTGTTA-GCGAATAC)',
  ],
  'NEXTflex® v2 UDI Barcodes 1 - 96': [
    'UDI 1 (AATCGTTA-AATAACGT)',
    'UDI 2 (GTCTACAT-TTCTTGAA)',
    'UDI 3 (CGCTGCTC-GGCAGATC)',
    'UDI 4 (GATCAACA-CTATGTTA)',
    'UDI 5 (CGAAGGAC-GTTGACGC)',
    'UDI 6 (GATGCCGG-ATCTACGA)',
    'UDI 7 (CTACGAAG-CTCGACAG)',
    'UDI 8 (GATGCGTC-GAGGCTGC)',
    'UDI 9 (CTACGGCA-CCTCGTAG)',
    'UDI 10 (GATTCCTT-CATAGGCA)',
    'UDI 11 (CTACTCGA-AGATGAAC)',
    'UDI 12 (GATTCGAG-CCGAGTAT)',
    'UDI 13 (AATCGGCG-AATATTGA)',
    'UDI 14 (TTCGCCGA-GTATACCG)',
    'UDI 15 (CTGGCCTC-GATCCAAC)',
    'UDI 16 (GAACTTAT-AGATACGC)',
    'UDI 17 (CGTATTGG-GGTATCTT)',
    'UDI 18 (GAAGCACA-CCTCTGGC)',
    'UDI 19 (CTTAATAC-CCATTGTG)',
    'UDI 20 (GAAGTCTT-ACTACGGT)',
    'UDI 21 (GAAGAGGC-AAGTGCTA)',
    'UDI 22 (CGGATAAC-GCCGAACG)',
    'UDI 23 (GAATCTGG-TGTCCACG)',
    'UDI 24 (CTGATTGA-GACACACT)',
    'UDI 25 (AATCCGTT-AATATGCT)',
    'UDI 26 (TGCGTACA-TTCTCATA)',
    'UDI 27 (GAATCAAT-TCTGTGAT)',
    'UDI 28 (TGAGTCAG-CCGAACTT)',
    'UDI 29 (GAATGCTC-GTCTAACA)',
    'UDI 30 (GAATATCC-GACGCCAT)',
    'UDI 31 (CTTATGAA-GCCAATGT)',
    'UDI 32 (TCGGCACC-CCAACGTC)',
    'UDI 33 (AAGAAGCG-GTAGATAA)',
    'UDI 34 (CTCACGAT-CTTACGGC)',
    'UDI 35 (TCGGTCGA-CCAAGTGC)',
    'UDI 36 (TCGGTAAG-CTAACTCA)',
    'UDI 37 (AAGATACA-AATATCTG)',
    'UDI 38 (GTCGCTGT-TTATATCA)',
    'UDI 39 (TCGGATGT-CTGCGGAT)',
    'UDI 40 (CGAGCCGG-GCGGCTTG)',
    'UDI 41 (CGATTATC-GAGTTGAT)',
    'UDI 42 (TCGAAGCT-GCACTGAG)',
    'UDI 43 (CTATCATT-GACCACCT)',
    'UDI 44 (CGCGCCAA-TGGCTAGG)',
    'UDI 45 (CGAACGGA-CCTACCGG)',
    'UDI 46 (CTACTGAC-GGAGGATG)',
    'UDI 47 (TCTTAAGT-CGCTGAAT)',
    'UDI 48 (TTAGAGTC-TGTGACGA)',
    'UDI 49 (AAGACGAA-AATAGATT)',
    'UDI 50 (TTATTATG-TTAGCGCA)',
    'UDI 51 (CGCTATTA-GCGGCCGT)',
    'UDI 52 (TCTATCAG-CAGTAACC)',
    'UDI 53 (CGGTGGTA-GCCTAGTA)',
    'UDI 54 (TCACCAAT-CACGGCGC)',
    'UDI 55 (CTGGAAGC-GGTGCAGA)',
    'UDI 56 (TCCTCGAT-GTAACTGC)',
    'UDI 57 (AAGAGAGC-CAGCCAGT)',
    'UDI 58 (TCAACGAG-CGTCAACC)',
    'UDI 59 (TGCGAGAC-GCCGGCGA)',
    'UDI 60 (CCTGGTGT-GCCTCCGG)',
    'UDI 61 (AAGTAAGT-AATAGTCC)',
    'UDI 62 (TGACTGAA-TTAGACGT)',
    'UDI 63 (AAGACTGT-GTGGACTA)',
    'UDI 64 (CAATGATG-CACGGACG)',
    'UDI 65 (CACAGTAA-CACTAGAG)',
    'UDI 66 (TGGTCATT-GCAGATGG)',
    'UDI 67 (CAACCGTG-CTCTCACG)',
    'UDI 68 (TGGTGCAC-GGAATCAC)',
    'UDI 69 (CCACAATG-CGTTGACG)',
    'UDI 70 (TGTGTGCC-CATCAGGT)',
    'UDI 71 (CACCACGG-CGTTGTAA)',
    'UDI 72 (TGTGTTAA-GGCACGGT)',
    'UDI 73 (AAGTTATC-AATAGCAA)',
    'UDI 74 (GTACAGCT-TGATCGGT)',
    'UDI 75 (CAACTGCT-AGTAGTAT)',
    'UDI 76 (CATGATGA-GTTAGAGG)',
    'UDI 77 (TGACTACT-CCTTACAG)',
    'UDI 78 (CAGAAGAT-GTACATTG)',
    'UDI 79 (TGAGGCGC-GGAGACCA)',
    'UDI 80 (CAGGTTCC-CGAACACC)',
    'UDI 81 (TGAACAGG-GAGAACAA)',
    'UDI 82 (CAGTGTGG-TGTGAATC)',
    'UDI 83 (TTCCACCA-GGTTAAGG)',
    'UDI 84 (CCGCTGTT-AGACCGCA)',
    'UDI 85 (AAGTTGGA-AATACAGG)',
    'UDI 86 (GGACAACG-TGATGGCC)',
    'UDI 87 (TTCGAACC-TGTCACCT)',
    'UDI 88 (CAGACCAC-GCTTCGGC)',
    'UDI 89 (TTCTGGTG-CCAGTGGT)',
    'UDI 90 (CAATCGAA-GCACACGC)',
    'UDI 91 (AAGTACAG-GTCACGTC)',
    'UDI 92 (CCGTGCCA-GCAGCTCC)',
    'UDI 93 (CATTGCAC-CATGCAGC)',
    'UDI 94 (TTACCTGG-ACGATTGC)',
    'UDI 95 (CTGCAACG-GACATTCG)',
    'UDI 96 (TACTGTTA-GCGAATAC)',
  ],
  'TruSeq DNA HT Dual-index (D7-D5)': [
    'A01 - D701-D501 (ATTACTCG-TATAGCCT)',
    'B01 - D701-D502 (ATTACTCG-ATAGAGGC)',
    'C01 - D701-D503 (ATTACTCG-CCTATCCT)',
    'D01 - D701-D504 (ATTACTCG-GGCTCTGA)',
    'E01 - D701-D505 (ATTACTCG-AGGCGAAG)',
    'F01 - D701-D506 (ATTACTCG-TAATCTTA)',
    'G01 - D701-D507 (ATTACTCG-CAGGACGT)',
    'H01 - D701-D508 (ATTACTCG-GTACTGAC)',
    'A02 - D702-D501 (TCCGGAGA-TATAGCCT)',
    'B02 - D702-D502 (TCCGGAGA-ATAGAGGC)',
    'C02 - D702-D503 (TCCGGAGA-CCTATCCT)',
    'D02 - D702-D504 (TCCGGAGA-GGCTCTGA)',
    'E02 - D702-D505 (TCCGGAGA-AGGCGAAG)',
    'F02 - D702-D506 (TCCGGAGA-TAATCTTA)',
    'G02 - D702-D507 (TCCGGAGA-CAGGACGT)',
    'H02 - D702-D508 (TCCGGAGA-GTACTGAC)',
    'A03 - D703-D501 (CGCTCATT-TATAGCCT)',
    'B03 - D703-D502 (CGCTCATT-ATAGAGGC)',
    'C03 - D703-D503 (CGCTCATT-CCTATCCT)',
    'D03 - D703-D504 (CGCTCATT-GGCTCTGA)',
    'E03 - D703-D505 (CGCTCATT-AGGCGAAG)',
    'F03 - D703-D506 (CGCTCATT-TAATCTTA)',
    'G03 - D703-D507 (CGCTCATT-CAGGACGT)',
    'H03 - D703-D508 (CGCTCATT-GTACTGAC)',
    'A04 - D704-D501 (GAGATTCC-TATAGCCT)',
    'B04 - D704-D502 (GAGATTCC-ATAGAGGC)',
    'C04 - D704-D503 (GAGATTCC-CCTATCCT)',
    'D04 - D704-D504 (GAGATTCC-GGCTCTGA)',
    'E04 - D704-D505 (GAGATTCC-AGGCGAAG)',
    'F04 - D704-D506 (GAGATTCC-TAATCTTA)',
    'G04 - D704-D507 (GAGATTCC-CAGGACGT)',
    'H04 - D704-D508 (GAGATTCC-GTACTGAC)',
    'A05 - D705-D501 (ATTCAGAA-TATAGCCT)',
    'B05 - D705-D502 (ATTCAGAA-ATAGAGGC)',
    'C05 - D705-D503 (ATTCAGAA-CCTATCCT)',
    'D05 - D705-D504 (ATTCAGAA-GGCTCTGA)',
    'E05 - D705-D505 (ATTCAGAA-AGGCGAAG)',
    'F05 - D705-D506 (ATTCAGAA-TAATCTTA)',
    'G05 - D705-D507 (ATTCAGAA-CAGGACGT)',
    'H05 - D705-D508 (ATTCAGAA-GTACTGAC)',
    'A06 - D706-D501 (GAATTCGT-TATAGCCT)',
    'B06 - D706-D502 (GAATTCGT-ATAGAGGC)',
    'C06 - D706-D503 (GAATTCGT-CCTATCCT)',
    'D06 - D706-D504 (GAATTCGT-GGCTCTGA)',
    'E06 - D706-D505 (GAATTCGT-AGGCGAAG)',
    'F06 - D706-D506 (GAATTCGT-TAATCTTA)',
    'G06 - D706-D507 (GAATTCGT-CAGGACGT)',
    'H06 - D706-D508 (GAATTCGT-GTACTGAC)',
    'A07 - D707-D501 (CTGAAGCT-TATAGCCT)',
    'B07 - D707-D502 (CTGAAGCT-ATAGAGGC)',
    'C07 - D707-D503 (CTGAAGCT-CCTATCCT)',
    'D07 - D707-D504 (CTGAAGCT-GGCTCTGA)',
    'E07 - D707-D505 (CTGAAGCT-AGGCGAAG)',
    'F07 - D707-D506 (CTGAAGCT-TAATCTTA)',
    'G07 - D707-D507 (CTGAAGCT-CAGGACGT)',
    'H07 - D707-D508 (CTGAAGCT-GTACTGAC)',
    'A08 - D708-D501 (TAATGCGC-TATAGCCT)',
    'B08 - D708-D502 (TAATGCGC-ATAGAGGC)',
    'C08 - D708-D503 (TAATGCGC-CCTATCCT)',
    'D08 - D708-D504 (TAATGCGC-GGCTCTGA)',
    'E08 - D708-D505 (TAATGCGC-AGGCGAAG)',
    'F08 - D708-D506 (TAATGCGC-TAATCTTA)',
    'G08 - D708-D507 (TAATGCGC-CAGGACGT)',
    'H08 - D708-D508 (TAATGCGC-GTACTGAC)',
    'A09 - D709-D501 (CGGCTATG-TATAGCCT)',
    'B09 - D709-D502 (CGGCTATG-ATAGAGGC)',
    'C09 - D709-D503 (CGGCTATG-CCTATCCT)',
    'D09 - D709-D504 (CGGCTATG-GGCTCTGA)',
    'E09 - D709-D505 (CGGCTATG-AGGCGAAG)',
    'F09 - D709-D506 (CGGCTATG-TAATCTTA)',
    'G09 - D709-D507 (CGGCTATG-CAGGACGT)',
    'H09 - D709-D508 (CGGCTATG-GTACTGAC)',
    'A10 - D710-D501 (TCCGCGAA-TATAGCCT)',
    'B10 - D710-D502 (TCCGCGAA-ATAGAGGC)',
    'C10 - D710-D503 (TCCGCGAA-CCTATCCT)',
    'D10 - D710-D504 (TCCGCGAA-GGCTCTGA)',
    'E10 - D710-D505 (TCCGCGAA-AGGCGAAG)',
    'F10 - D710-D506 (TCCGCGAA-TAATCTTA)',
    'G10 - D710-D507 (TCCGCGAA-CAGGACGT)',
    'H10 - D710-D508 (TCCGCGAA-GTACTGAC)',
    'A11 - D711-D501 (TCTCGCGC-TATAGCCT)',
    'B11 - D711-D502 (TCTCGCGC-ATAGAGGC)',
    'C11 - D711-D503 (TCTCGCGC-CCTATCCT)',
    'D11 - D711-D504 (TCTCGCGC-GGCTCTGA)',
    'E11 - D711-D505 (TCTCGCGC-AGGCGAAG)',
    'F11 - D711-D506 (TCTCGCGC-TAATCTTA)',
    'G11 - D711-D507 (TCTCGCGC-CAGGACGT)',
    'H11 - D711-D508 (TCTCGCGC-GTACTGAC)',
    'A12 - D712-D501 (AGCGATAG-TATAGCCT)',
    'B12 - D712-D502 (AGCGATAG-ATAGAGGC)',
    'C12 - D712-D503 (AGCGATAG-CCTATCCT)',
    'D12 - D712-D504 (AGCGATAG-GGCTCTGA)',
    'E12 - D712-D505 (AGCGATAG-AGGCGAAG)',
    'F12 - D712-D506 (AGCGATAG-TAATCTTA)',
    'G12 - D712-D507 (AGCGATAG-CAGGACGT)',
    'H12 - D712-D508 (AGCGATAG-GTACTGAC)',
  ],
  'TWIST UDI Set A': [
    'A01 TWIST (TATCTTCAGC- CCAATATTCG)',
    'B01 TWIST (TGCACGGATA- CGCAGACAAC)',
    'C01 TWIST (GGTTGATAGA- TCGGAGCAGA)',
    'D01 TWIST (ACTCCTGCCT- GAGTCCGTAG)',
    'E01 TWIST (CCGATAGTCG- ATGTTCACGT)',
    'F01 TWIST (CAAGATCGAA- TTCGATGGTT)',
    'G01 TWIST (AGGCTCCTTC- TATCCGTGCA)',
    'H01 TWIST (ATACGGATAG- AAGCGCAGAG)',
    'A02 TWIST (AATAGCCTCA- CCGACTTAGT)',
    'B02 TWIST (CTGCAATCGG- TTCTGCATCG)',
    'C02 TWIST (CCTGAGTTAT- GGAAGTGCCA)',
    'D02 TWIST (GACGTCCAGA- AGATTCAACC)',
    'E02 TWIST (GAATAATCGG- TTCAGGAGAT)',
    'F02 TWIST (CGGAGTGTGT- AAGGCGTCTG)',
    'G02 TWIST (TTACCGACCG- ACGCTTGACA)',
    'H02 TWIST (AGTGTTCGCC- CATGAAGTGA)',
    'A03 TWIST (CTACGTTCTT- TTACGACCTG)',
    'B03 TWIST (TCGACACGAA- ATGCAAGCCG)',
    'C03 TWIST (CCGATAACTT- CTCCGTATAC)',
    'D03 TWIST (TTGGACATCG- GAATCTGGTC)',
    'E03 TWIST (AACGTTGAGA- CGGTCGGTAA)',
    'F03 TWIST (GGCCAGTGAA- TCTGCTAATG)',
    'G03 TWIST (ATGTCTCCGG- CTCTTATTCG)',
    'H03 TWIST (GAAGGCGTTC- CACCTCTAGC)',
    'A04 TWIST (TGTTCCTAGA- TTACTTACCG)',
    'B04 TWIST (CTCTCGAGGT- CTATGCCTTA)',
    'C04 TWIST (CTGTACGGTA- GGAAGGTACG)',
    'D04 TWIST (CTTATGGCAA- GAGGAGACGT)',
    'E04 TWIST (TCCGCATAGC- ACGCAAGGCA)',
    'F04 TWIST (GCAAGCACCT- TATCCTGACG)',
    'G04 TWIST (GCCTGTCCTA- GAAGACCGCT)',
    'H04 TWIST (ACTGTCTATC- CAACGTGGAC)',
    'A05 TWIST (CGTCCATGTA- TAAGTGCTCG)',
    'B05 TWIST (CTAACTGCAA- CACATCGTAG)',
    'C05 TWIST (TGCTTGTGGT- ACTACCGAGG)',
    'D05 TWIST (TGTAAGCACA- GATGTGTTCT)',
    'E05 TWIST (CTCGTTGCGT- AAGTGTCGTA)',
    'F05 TWIST (GCTAGAGGTG- GGAGAACCAC)',
    'G05 TWIST (AAGCGGAGAA- TGTACGAACT)',
    'H05 TWIST (AATGACGCTG- GGATGAGTGC)',
    'A06 TWIST (TTGGTACGCG- TAGTAGGACA)',
    'B06 TWIST (TGAAGGTGAA- ACGCCTCGTT)',
    'C06 TWIST (GTAGTGGCTT- CACCGCTGTT)',
    'D06 TWIST (CGTAACAGAA- TCTATAGCGG)',
    'E06 TWIST (AAGGCCATAA- CCGATGGACA)',
    'F06 TWIST (TTCATAGACC- TTCAACATGC)',
    'G06 TWIST (CCAACTCCGA- GGAGTAACGC)',
    'H06 TWIST (CACGAGTATG- AGCCTTAGCG)',
    'A07 TWIST (CCGCTACCAA- TTACCTCAGT)',
    'B07 TWIST (CTGAACCTCC- CAGGCATTGT)',
    'C07 TWIST (GGCCTTGTTA- GTGTTCCACG)',
    'D07 TWIST (TTAACGCAGA- TTGATCCGCC)',
    'E07 TWIST (AGGTAGTGCG- GGAGGCTGAT)',
    'F07 TWIST (CGTGTAACTT- AACGTGACAA)',
    'G07 TWIST (ACTTGTGACG- CACAAGCTCC)',
    'H07 TWIST (CCATGCGTTG- CCGTGTTGTC)',
    'A08 TWIST (CCTTGTAGCG- TTGAGCCAGC)',
    'B08 TWIST (ACATACGTGA- GCGTTACAGA)',
    'C08 TWIST (CTTGATATCC- TCCAGACATT)',
    'D08 TWIST (CAGCCGATGT- TCGAACTCTT)',
    'E08 TWIST (TCATGCGCTA- ACCTTCTCGG)',
    'F08 TWIST (ACTCCGTCCA- AGACGCCAAC)',
    'G08 TWIST (GACAGCCTTG- CAACCGTAAT)',
    'H08 TWIST (CGGTTATCTG- TTATGCGTTG)',
    'A09 TWIST (TACTCCACGG- CTATGAGAAC)',
    'B09 TWIST (ACTTCCGGCA- AAGTTACACG)',
    'C09 TWIST (GTGAAGCTGC- GCAATGTGAG)',
    'D09 TWIST (TTGCTCTTCT- CGAAGTCGCA)',
    'E09 TWIST (AACGCACGTA- CCTGATTCAA)',
    'F09 TWIST (TTACTGCAGG- TAGAACGTGC)',
    'G09 TWIST (CCAGTTGAGG- TTCGCAAGGT)',
    'H09 TWIST (TGTGCGTTAA- TTAATGCCGA)',
    'A10 TWIST (ACTAGTGCTT- AGAACAGAGT)',
    'B10 TWIST (CGTGGAACAC- CCATCTGTTC)',
    'C10 TWIST (ATGGAAGTGG- TTCGTAGGTG)',
    'D10 TWIST (TGAGATCACA- GCACGGTACA)',
    'E10 TWIST (GTCCTTGGTG- TGTCAAGAGG)',
    'F10 TWIST (GAGCGTGGAA- TCTAAGGTAC)',
    'G10 TWIST (CACACGCTGT- GAACGGAGAC)',
    'H10 TWIST (TGGTTGTACA- CGCTACCATC)',
    'A11 TWIST (ATCACTCACA- TTACGGTAAC)',
    'B11 TWIST (CGGAGGTAGA- TTCAGATGGA)',
    'C11 TWIST (GAGTTGACAA- TAGCATCTGT)',
    'D11 TWIST (GCCGAACTTG- GGACGAGATC)',
    'E11 TWIST (AGGCCTCACA- AGGTTCTGTT)',
    'F11 TWIST (TCTCTGTTAG- CATACTCGTG)',
    'G11 TWIST (TCCGACGATT- CCGGATACCA)',
    'H11 TWIST (AGGCTATGTT- ATGTCCACCG)',
    'A12 TWIST (CGTTCTCTTG- CACCAAGTGG)',
    'B12 TWIST (TTGTCTATGG- TTGAGTACAC)',
    'C12 TWIST (GATGGATACA- CGGTTCCGTA)',
    'D12 TWIST (CACTTAGGCG- GGAGGTCCTA)',
    'E12 TWIST (ACACTGGCTA- CCTGCTTGGA)',
    'F12 TWIST (ATCGCCACTG- TTCACGTCAG)',
    'G12 TWIST (CTGACGTGAA- AACATAGCCT)',
    'H12 TWIST (TCAATCGTCT- TGACATAGTC)',
  ],
  'TWIST UDI Set B': [
    'A01 TWIST (ATCGCCTATA-TTGGCTCATA)',
    'B01 TWIST (CGGATTCCTG-CAGAATACGG)',
    'C01 TWIST (TCACACGTGG-TGTATAGGTC)',
    'D01 TWIST (GCAGCATTCC-GTATACCACA)',
    'E01 TWIST (CCGTGGTGAA-AACTGGACGG)',
    'F01 TWIST (CACAGAACGG-TGTGAGTGAT)',
    'G01 TWIST (ATGGATCGAA-AACTCAGCAA)',
    'H01 TWIST (GGTCTCACCT-AGACGATTGA)',
    'A02 TWIST (CAACACCGTA-CGGCTTGTTC)',
    'B02 TWIST (CGAATATTGG-TTCCGTGCTG)',
    'C02 TWIST (TAATTCCAGC-CGAATACGAT)',
    'D02 TWIST (GTCGCGGTTA-ACCTCACCAG)',
    'E02 TWIST (TTCTGCGTCG-TTCGTACACC)',
    'F02 TWIST (ACGCATACTT-AAGTACGAGA)',
    'G02 TWIST (GGCTGCACAA-TCGGACCTCT)',
    'H02 TWIST (ACCAAGCCAA-CCGCCTTGTA)',
    'A03 TWIST (CCAATTGTCC-GCGTATGAGC)',
    'B03 TWIST (CAGACGCCTT-TTGAGCTCTG)',
    'C03 TWIST (AATTGCCAGA-AACGTACCGT)',
    'D03 TWIST (TGATACCAGA-GGCCTTCACA)',
    'E03 TWIST (GAGGTTGTTA-TGTGCACTGG)',
    'F03 TWIST (AGAGTATCAG-GGATACAGGT)',
    'G03 TWIST (CTGGCGTATG-CCAATGTTAC)',
    'H03 TWIST (GGTCATCTCG-GCTATGCGGA)',
    'A04 TWIST (TGTCGAACAA-CCAGAATCTA)',
    'B04 TWIST (GTGGCACGAA-CCAATTAGCA)',
    'C04 TWIST (AAGCCTTAGA-CGTGTTATGA)',
    'D04 TWIST (CGCTAAGGCT-TGTGCCGGTT)',
    'E04 TWIST (AATCACGACC-CACCAGAAGT)',
    'F04 TWIST (GTAGCTGTCG-TCTGCGTTAA)',
    'G04 TWIST (CACGTAAGGT-AGCTTAGAGG)',
    'H04 TWIST (TCACTTCATG-TTGCGACCAC)',
    'A05 TWIST (GTTGGCGTCT-CGAAGTCTAG)',
    'B05 TWIST (CACACGCCAA-GCTGAAGATA)',
    'C05 TWIST (ACACTGTGAA-TCTGTTAGAC)',
    'D05 TWIST (CGATTGTTCT-TGTACAACCA)',
    'E05 TWIST (TCGGCTACTG-CTATTGTGTG)',
    'F05 TWIST (TTGTAAGAGG-GAAGCAGCTG)',
    'G05 TWIST (CGAGTCCGTT-CCGCAGTAGT)',
    'H05 TWIST (GTGTACTCAA-AAGGTTGCTT)',
    'A06 TWIST (GCGTGACGTT-CTCTCTTCTA)',
    'B06 TWIST (AGGCGTCTGA-GGATCTTGTG)',
    'C06 TWIST (ACTTACGAGG-AGCGATTAAC)',
    'D06 TWIST (CAGGTCGTAA-GAAGGCATAA)',
    'E06 TWIST (TACGCTAGTT-AGCAGACTAA)',
    'F06 TWIST (TCTGTCGTGC-AAGCACTAGT)',
    'G06 TWIST (GATCTTGGCA-TTAGACAGCG)',
    'H06 TWIST (TGGAGAGCCA-TTAGGCACAA)',
    'A07 TWIST (ACCAATCTCG-TTCCGGCACT)',
    'B07 TWIST (GTCGTGACAC-TTGTATGGCT)',
    'C07 TWIST (TCTCTAGTCG-TGGATCGATT)',
    'D07 TWIST (ATTACGGTTG-CGGAATCACC)',
    'E07 TWIST (CGGTAAGTAA-GAGCTATCTA)',
    'F07 TWIST (TAACGTCCGG-ACCTCGAGAG)',
    'G07 TWIST (GAACACAGTT-CCGAATTCAC)',
    'H07 TWIST (AGGTCCTATA-AACGTCACGC)',
    'A08 TWIST (TTGACCTAGC-TTGGTGTTCC)',
    'B08 TWIST (GCTTCAATCA-CCAGGTGGAA)',
    'C08 TWIST (TGCGTGCGAA-TCATACCGAT)',
    'D08 TWIST (AATGGTACCT-CGACGGTTGT)',
    'E08 TWIST (TGTATCGCGA-CACTCACACG)',
    'F08 TWIST (GTAACATTGG-TTGGCCACGA)',
    'G08 TWIST (CAACAATTCG-AATCGGTCGC)',
    'H08 TWIST (GCGTGTCATG-AGAACAATCG)',
    'A09 TWIST (TAGATCCGAA-CTATCGAAGT)',
    'B09 TWIST (TCTTAACTGG-TCGGCCTGAA)',
    'C09 TWIST (GTCACATCCG-TCACTGTTCT)',
    'D09 TWIST (TGAAGCATCT-GGTATCTAAC)',
    'E09 TWIST (CGGACTACTT-CGTATTAAGG)',
    'F09 TWIST (AACGGAGTCC-TAGGAGTGTC)',
    'G09 TWIST (AGGTGTGACC-CTCCGAACTC)',
    'H09 TWIST (CCAGAGTTCC-ATGTCTCTCG)',
    'A10 TWIST (CCAGTGATTG-AGGTGCACTT)',
    'B10 TWIST (GACTGACATA-TTGGCCGCAT)',
    'C10 TWIST (GCGATCCTTG-GGTGTCTGAG)',
    'D10 TWIST (TGTTCCACTT-CCGTGCCATT)',
    'E10 TWIST (ATCCAATAGG-AAGATGACGA)',
    'F10 TWIST (AGACCGTTAA-TGTATTGCCA)',
    'G10 TWIST (ACTATTGACC-AACCATCGGC)',
    'H10 TWIST (GCCTAATTCC-CGTGCAACCT)',
    'A11 TWIST (GTAGGTACAA-TTCTTGAGTG)',
    'B11 TWIST (TGCGACTTCG-TCTGCAACAA)',
    'C11 TWIST (TTGTCACGTT-CCGCTACACA)',
    'D11 TWIST (CAACGACTGA-CTCTGTCAGG)',
    'E11 TWIST (GATTCGGCTA-TTAACGGTCT)',
    'F11 TWIST (TGGTGGCTAG-CGATGACCTT)',
    'G11 TWIST (AGGCCAGGAT-AGGCAGGAGT)',
    'H11 TWIST (AACGCCTGTG-AACGGACTCG)',
    'A12 TWIST (CGTGTGAGTG-TTGGTTCGGC)',
    'B12 TWIST (CGTATGTGAA-CGCACTACCT)',
    'C12 TWIST (TACGTCACAA-CCATACCACG)',
    'D12 TWIST (GGAAGATCCG-GAATTCGGTA)',
    'E12 TWIST (CATGTCAGCT-AGTCCTCCAC)',
    'F12 TWIST (ACAGCGTCAC-TAGTCATTCG)',
    'G12 TWIST (TGTTACAAGG-TTGAGGTCGC)',
    'H12 TWIST (CTTATAGAGG-CAACGTTATG)',
  ],
  'TWIST UDI Set C': [
    'A01 TWIST (TCGAAGTCAA-CGGAGGAATG)',
    'B01 TWIST (CGAGGCCTAT-GAGTCAGCCA)',
    'C01 TWIST (TCCTGAAGTG-GGAATTAGGC)',
    'D01 TWIST (AATCCTTACC-TTCGCCACAC)',
    'E01 TWIST (TTGTTGCAGA-CCTCGCTTAC)',
    'F01 TWIST (AATCTAGGCC-ACAGCGTGTG)',
    'G01 TWIST (GGCTCTACTG-TTCCGCTTCT)',
    'H01 TWIST (GTCCACGTTG-CAGCGTCATT)',
    'A02 TWIST (CTCCGCAGTT-CCGTAGAACA)',
    'B02 TWIST (AGAACAGTGA-CGGTTATCGT)',
    'C02 TWIST (GCTCTTATTG-TCTGGTATCA)',
    'D02 TWIST (TGTAGACGAA-AAGTATGCGT)',
    'E02 TWIST (CTTGTCGTCG-TTCCTTCGAG)',
    'F02 TWIST (TCGTCTTACA-GCTATGGATA)',
    'G02 TWIST (GAGAGGAGGA-AGGTACCATT)',
    'H02 TWIST (GTTAGATACC-TTACGGAGTC)',
    'A03 TWIST (GGCTTAAGAA-TGAGGACTTA)',
    'B03 TWIST (TCTGGTACAA-TTGAGTTGCC)',
    'C03 TWIST (GTGAATTCGG-AGCTTCGCGA)',
    'D03 TWIST (GAATGGAGAA-CATACGCCAG)',
    'E03 TWIST (AGTCAATTGG-CAAGACCAGC)',
    'F03 TWIST (CGCATCACCT-GATAGACAGT)',
    'G03 TWIST (TATTGACACC-CGCTCGTGAA)',
    'H03 TWIST (AGACTGTCGG-TCTCTAACAG)',
    'A04 TWIST (ATCTGGACTC-ACCTAGGAGG)',
    'B04 TWIST (GAGAATAAGG-TCTGTACCTT)',
    'C04 TWIST (TGTTGTCGCC-CTCAGGCCAT)',
    'D04 TWIST (CTGCGGTGTT-TTGTGCAGCC)',
    'E04 TWIST (GATAACTCCG-TAGCCGAATC)',
    'F04 TWIST (ATCCTTGTAC-AAGCCTGTTA)',
    'G04 TWIST (TACGCGTATA-TGTACAGTAG)',
    'H04 TWIST (CCACCAATTG-CGATTCTGCC)',
    'A05 TWIST (TGTGAAGGCC-TTGCTAAGGA)',
    'B05 TWIST (CCTTGACTGC-ACTCCTTGGC)',
    'C05 TWIST (AATGCGTCGG-GAAGGCGAAC)',
    'D05 TWIST (AAGACTACAC-CAATACCTTG)',
    'E05 TWIST (GTCAGTGCAG-CGACGACAAG)',
    'F05 TWIST (CTCACCAGAA-GAACCTGACC)',
    'G05 TWIST (TCTCGTACTT-TTGCCTCGCA)',
    'H05 TWIST (TCAGATTAGG-TTCGTGTCGA)',
    'A06 TWIST (CACTCAAGAA-TGGATGGCAA)',
    'B06 TWIST (AGAGCCATTC-TTCACCAGCT)',
    'C06 TWIST (CACGATTCCG-CCTGAGTAGC)',
    'D06 TWIST (TTGGAGCCTG-AGGTGTCCGT)',
    'E06 TWIST (TTACGACTTG-GTCTGGTTGC)',
    'F06 TWIST (TTAAGGTCGG-CTCTTAGATG)',
    'G06 TWIST (GGTTCTGTCA-TATCACCTGC)',
    'H06 TWIST (GATACGCACC-CAGAGGCAAG)',
    'A07 TWIST (TCGCGAAGCT-CCGGTCAACA)',
    'B07 TWIST (GTTAAGACGG-TCACGAGGTG)',
    'C07 TWIST (CCGGTCATAC-CCATAGACAA)',
    'D07 TWIST (GTCAGCTTAA-GAGCTTGGAC)',
    'E07 TWIST (ACCGCGGATA-TACGGTGTTG)',
    'F07 TWIST (GTTGCATCAA-TTCAACTCGA)',
    'G07 TWIST (TGTGCACCAA-AAGGCAGGTA)',
    'H07 TWIST (ATCTGTGGTC-CGGCCAATTC)',
    'A08 TWIST (CACAAGATCC-CAACCGGACA)',
    'B08 TWIST (CTGCTAGCTG-AACTTGGCCG)',
    'C08 TWIST (ACCGGTCGAA-TGGAACATAG)',
    'D08 TWIST (GCACGTTCTA-TTCGGATCTA)',
    'E08 TWIST (AAGGAAGGAA-CGGAATCGTG)',
    'F08 TWIST (AGAGAGATAG-TCTAATCGGT)',
    'G08 TWIST (GGTTCCTATT-GCTGGAATTA)',
    'H08 TWIST (TTCACGAGCG-CGCTTCTCAC)',
    'A09 TWIST (GGCACAACCT-TAGACTCCTG)',
    'B09 TWIST (TGACTCAGAA-CCGTTGATTG)',
    'C09 TWIST (CGATCTCAGG-CGAACCTCCA)',
    'D09 TWIST (CCTGCTGGAA-TTGGAAGTTG)',
    'E09 TWIST (GAGCTGTATA-CCAGGAGTAC)',
    'F09 TWIST (AACCTGACGG-AGGTTCGTCG)',
    'G09 TWIST (AAGCTCGTGG-GACCTGAAGA)',
    'H09 TWIST (GTCCAAGCTC-TTAACGCACA)',
    'A10 TWIST (CTAGACTTCG-TCGGAGTTGG)',
    'B10 TWIST (TCCAAGGTAA-CGATGACTCC)',
    'C10 TWIST (CTTGGTAGCA-TATAGGTTGG)',
    'D10 TWIST (AACGAGGCGT-GACAAGTGTT)',
    'E10 TWIST (CAGAAGATGG-TTCTCCGGAA)',
    'F10 TWIST (TGATACATCC-ACACACTCCG)',
    'G10 TWIST (GCGCGTAGTT-CTGGTCACTA)',
    'H10 TWIST (GTTGTCTGCG-TTCGTGCCAC)',
    'A11 TWIST (CTTAGCGCTG-AGATCATGGA)',
    'B11 TWIST (ATCAGCCTCC-GAGTATGTAC)',
    'C11 TWIST (TGCAGTGCTC-TAGAACACCT)',
    'D11 TWIST (GAGCTCAGAC-CCAGTTAAGA)',
    'E11 TWIST (ACCTGGACAA-CGCTTATCTG)',
    'F11 TWIST (CAACTTCCAA-GAGCTCTTAC)',
    'G11 TWIST (CCATCCTGTG-TCTCAAGGCG)',
    'H11 TWIST (GGCAGTTAGA-CTAAGTACCA)',
    'A12 TWIST (TCACATGAGA-TCGACAAGCC)',
    'B12 TWIST (TATTCGTTGG-TTCGACATCA)',
    'C12 TWIST (AGCGGTCTTC-AGTGGTACTT)',
    'D12 TWIST (GCGACCGATT-TTGCACTTGT)',
    'E12 TWIST (GATCTCGTCC-GTCTTCGCAG)',
    'F12 TWIST (CCATTATAGG-CAGGCTCCAA)',
    'G12 TWIST (ACAGACCACG-CCAGGTTACG)',
    'H12 TWIST (ATTCCACACA-CAATCGCCTA)',
  ],
  'Nextera XT Dual': [
    'N701-S502 (TAAGGCGA-CTCTCTAT)',
    'N701-S503 (TAAGGCGA-TATCCTCT)',
    'N701-S505 (TAAGGCGA-GTAAGGAG)',
    'N701-S506 (TAAGGCGA-ACTGCATA)',
    'N701-S507 (TAAGGCGA-AAGGAGTA)',
    'N701-S508 (TAAGGCGA-CTAAGCCT)',
    'N701-S510 (TAAGGCGA-CGTCTAAT)',
    'N701-S511 (TAAGGCGA-TCTCTCCG)',
    'N702-S502 (CGTACTAG-CTCTCTAT)',
    'N702-S503 (CGTACTAG-TATCCTCT)',
    'N702-S505 (CGTACTAG-GTAAGGAG)',
    'N702-S506 (CGTACTAG-ACTGCATA)',
    'N702-S507 (CGTACTAG-AAGGAGTA)',
    'N702-S508 (CGTACTAG-CTAAGCCT)',
    'N702-S510 (CGTACTAG-CGTCTAAT)',
    'N702-S511 (CGTACTAG-TCTCTCCG)',
    'N703-S502 (AGGCAGAA-CTCTCTAT)',
    'N703-S503 (AGGCAGAA-TATCCTCT)',
    'N703-S505 (AGGCAGAA-GTAAGGAG)',
    'N703-S506 (AGGCAGAA-ACTGCATA)',
    'N703-S507 (AGGCAGAA-AAGGAGTA)',
    'N703-S508 (AGGCAGAA-CTAAGCCT)',
    'N703-S510 (AGGCAGAA-CGTCTAAT)',
    'N703-S511 (AGGCAGAA-TCTCTCCG)',
    'N704-S502 (TCCTGAGC-CTCTCTAT)',
    'N704-S503 (TCCTGAGC-TATCCTCT)',
    'N704-S505 (TCCTGAGC-GTAAGGAG)',
    'N704-S506 (TCCTGAGC-ACTGCATA)',
    'N704-S507 (TCCTGAGC-AAGGAGTA)',
    'N704-S508 (TCCTGAGC-CTAAGCCT)',
    'N704-S510 (TCCTGAGC-CGTCTAAT)',
    'N704-S511 (TCCTGAGC-TCTCTCCG)',
    'N705-S502 (GGACTCCT-CTCTCTAT)',
    'N705-S503 (GGACTCCT-TATCCTCT)',
    'N705-S505 (GGACTCCT-GTAAGGAG)',
    'N705-S506 (GGACTCCT-ACTGCATA)',
    'N705-S507 (GGACTCCT-AAGGAGTA)',
    'N705-S508 (GGACTCCT-CTAAGCCT)',
    'N705-S510 (GGACTCCT-CGTCTAAT)',
    'N705-S511 (GGACTCCT-TCTCTCCG)',
    'N706-S502 (TAGGCATG-CTCTCTAT)',
    'N706-S503 (TAGGCATG-TATCCTCT)',
    'N706-S505 (TAGGCATG-GTAAGGAG)',
    'N706-S506 (TAGGCATG-ACTGCATA)',
    'N706-S507 (TAGGCATG-AAGGAGTA)',
    'N706-S508 (TAGGCATG-CTAAGCCT)',
    'N706-S510 (TAGGCATG-CGTCTAAT)',
    'N706-S511 (TAGGCATG-TCTCTCCG)',
    'N707-S502 (CTCTCTAC-CTCTCTAT)',
    'N707-S503 (CTCTCTAC-TATCCTCT)',
    'N707-S505 (CTCTCTAC-GTAAGGAG)',
    'N707-S506 (CTCTCTAC-ACTGCATA)',
    'N707-S507 (CTCTCTAC-AAGGAGTA)',
    'N707-S508 (CTCTCTAC-CTAAGCCT)',
    'N707-S510 (CTCTCTAC-CGTCTAAT)',
    'N707-S511 (CTCTCTAC-TCTCTCCG)',
    'N710-S502 (CGAGGCTG-CTCTCTAT)',
    'N710-S503 (CGAGGCTG-TATCCTCT)',
    'N710-S505 (CGAGGCTG-GTAAGGAG)',
    'N710-S506 (CGAGGCTG-ACTGCATA)',
    'N710-S507 (CGAGGCTG-AAGGAGTA)',
    'N710-S508 (CGAGGCTG-CTAAGCCT)',
    'N710-S510 (CGAGGCTG-CGTCTAAT)',
    'N710-S511 (CGAGGCTG-TCTCTCCG)',
    'N711-S502 (AAGAGGCA-CTCTCTAT)',
    'N711-S503 (AAGAGGCA-TATCCTCT)',
    'N711-S505 (AAGAGGCA-GTAAGGAG)',
    'N711-S506 (AAGAGGCA-ACTGCATA)',
    'N711-S507 (AAGAGGCA-AAGGAGTA)',
    'N711-S508 (AAGAGGCA-CTAAGCCT)',
    'N711-S510 (AAGAGGCA-CGTCTAAT)',
    'N711-S511 (AAGAGGCA-TCTCTCCG)',
    'N712-S502 (GTAGAGGA-CTCTCTAT)',
    'N712-S503 (GTAGAGGA-TATCCTCT)',
    'N712-S505 (GTAGAGGA-GTAAGGAG)',
    'N712-S506 (GTAGAGGA-ACTGCATA)',
    'N712-S507 (GTAGAGGA-AAGGAGTA)',
    'N712-S508 (GTAGAGGA-CTAAGCCT)',
    'N712-S510 (GTAGAGGA-CGTCTAAT)',
    'N712-S511 (GTAGAGGA-TCTCTCCG)',
    'N714-S502 (GCTCATGA-CTCTCTAT)',
    'N714-S503 (GCTCATGA-TATCCTCT)',
    'N714-S505 (GCTCATGA-GTAAGGAG)',
    'N714-S506 (GCTCATGA-ACTGCATA)',
    'N714-S507 (GCTCATGA-AAGGAGTA)',
    'N714-S508 (GCTCATGA-CTAAGCCT)',
    'N714-S510 (GCTCATGA-CGTCTAAT)',
    'N714-S511 (GCTCATGA-TCTCTCCG)',
    'N715-S502 (ATCTCAGG-CTCTCTAT)',
    'N715-S503 (ATCTCAGG-TATCCTCT)',
    'N715-S505 (ATCTCAGG-GTAAGGAG)',
    'N715-S506 (ATCTCAGG-ACTGCATA)',
    'N715-S507 (ATCTCAGG-AAGGAGTA)',
    'N715-S508 (ATCTCAGG-CTAAGCCT)',
    'N715-S510 (ATCTCAGG-CGTCTAAT)',
    'N715-S511 (ATCTCAGG-TCTCTCCG)',
    'N701-S513 (TAAGGCGA-TCGACTAG)',
    'N701-S515 (TAAGGCGA-TTCTAGCT)',
    'N701-S516 (TAAGGCGA-CCTAGAGT)',
    'N701-S517 (TAAGGCGA-GCGTAAGA)',
    'N701-S518 (TAAGGCGA-CTATTAAG)',
    'N701-S520 (TAAGGCGA-AAGGCTAT)',
    'N701-S521 (TAAGGCGA-GAGCCTTA)',
    'N701-S522 (TAAGGCGA-TTATGCGA)',
    'N702-S513 (CGTACTAG-TCGACTAG)',
    'N702-S515 (CGTACTAG-TTCTAGCT)',
    'N702-S516 (CGTACTAG-CCTAGAGT)',
    'N702-S517 (CGTACTAG-GCGTAAGA)',
    'N702-S518 (CGTACTAG-CTATTAAG)',
    'N702-S520 (CGTACTAG-AAGGCTAT)',
    'N702-S521 (CGTACTAG-GAGCCTTA)',
    'N702-S522 (CGTACTAG-TTATGCGA)',
    'N703-S513 (AGGCAGAA-TCGACTAG)',
    'N703-S515 (AGGCAGAA-TTCTAGCT)',
    'N703-S516 (AGGCAGAA-CCTAGAGT)',
    'N703-S517 (AGGCAGAA-GCGTAAGA)',
    'N703-S518 (AGGCAGAA-CTATTAAG)',
    'N703-S520 (AGGCAGAA-AAGGCTAT)',
    'N703-S521 (AGGCAGAA-GAGCCTTA)',
    'N703-S522 (AGGCAGAA-TTATGCGA)',
    'N704-S513 (TCCTGAGC-TCGACTAG)',
    'N704-S515 (TCCTGAGC-TTCTAGCT)',
    'N704-S516 (TCCTGAGC-CCTAGAGT)',
    'N704-S517 (TCCTGAGC-GCGTAAGA)',
    'N704-S518 (TCCTGAGC-CTATTAAG)',
    'N704-S520 (TCCTGAGC-AAGGCTAT)',
    'N704-S521 (TCCTGAGC-GAGCCTTA)',
    'N704-S522 (TCCTGAGC-TTATGCGA)',
    'N705-S513 (GGACTCCT-TCGACTAG)',
    'N705-S515 (GGACTCCT-TTCTAGCT)',
    'N705-S516 (GGACTCCT-CCTAGAGT)',
    'N705-S517 (GGACTCCT-GCGTAAGA)',
    'N705-S518 (GGACTCCT-CTATTAAG)',
    'N705-S520 (GGACTCCT-AAGGCTAT)',
    'N705-S521 (GGACTCCT-GAGCCTTA)',
    'N705-S522 (GGACTCCT-TTATGCGA)',
    'N706-S513 (TAGGCATG-TCGACTAG)',
    'N706-S515 (TAGGCATG-TTCTAGCT)',
    'N706-S516 (TAGGCATG-CCTAGAGT)',
    'N706-S517 (TAGGCATG-GCGTAAGA)',
    'N706-S518 (TAGGCATG-CTATTAAG)',
    'N706-S520 (TAGGCATG-AAGGCTAT)',
    'N706-S521 (TAGGCATG-GAGCCTTA)',
    'N706-S522 (TAGGCATG-TTATGCGA)',
    'N707-S513 (CTCTCTAC-TCGACTAG)',
    'N707-S515 (CTCTCTAC-TTCTAGCT)',
    'N707-S516 (CTCTCTAC-CCTAGAGT)',
    'N707-S517 (CTCTCTAC-GCGTAAGA)',
    'N707-S518 (CTCTCTAC-CTATTAAG)',
    'N707-S520 (CTCTCTAC-AAGGCTAT)',
    'N707-S521 (CTCTCTAC-GAGCCTTA)',
    'N707-S522 (CTCTCTAC-TTATGCGA)',
    'N710-S513 (CGAGGCTG-TCGACTAG)',
    'N710-S515 (CGAGGCTG-TTCTAGCT)',
    'N710-S516 (CGAGGCTG-CCTAGAGT)',
    'N710-S517 (CGAGGCTG-GCGTAAGA)',
    'N710-S518 (CGAGGCTG-CTATTAAG)',
    'N710-S520 (CGAGGCTG-AAGGCTAT)',
    'N710-S521 (CGAGGCTG-GAGCCTTA)',
    'N710-S522 (CGAGGCTG-TTATGCGA)',
    'N711-S513 (AAGAGGCA-TCGACTAG)',
    'N711-S515 (AAGAGGCA-TTCTAGCT)',
    'N711-S516 (AAGAGGCA-CCTAGAGT)',
    'N711-S517 (AAGAGGCA-GCGTAAGA)',
    'N711-S518 (AAGAGGCA-CTATTAAG)',
    'N711-S520 (AAGAGGCA-AAGGCTAT)',
    'N711-S521 (AAGAGGCA-GAGCCTTA)',
    'N711-S522 (AAGAGGCA-TTATGCGA)',
    'N712-S513 (GTAGAGGA-TCGACTAG)',
    'N712-S515 (GTAGAGGA-TTCTAGCT)',
    'N712-S516 (GTAGAGGA-CCTAGAGT)',
    'N712-S517 (GTAGAGGA-GCGTAAGA)',
    'N712-S518 (GTAGAGGA-CTATTAAG)',
    'N712-S520 (GTAGAGGA-AAGGCTAT)',
    'N712-S521 (GTAGAGGA-GAGCCTTA)',
    'N712-S522 (GTAGAGGA-TTATGCGA)',
    'N714-S513 (GCTCATGA-TCGACTAG)',
    'N714-S515 (GCTCATGA-TTCTAGCT)',
    'N714-S516 (GCTCATGA-CCTAGAGT)',
    'N714-S517 (GCTCATGA-GCGTAAGA)',
    'N714-S518 (GCTCATGA-CTATTAAG)',
    'N714-S520 (GCTCATGA-AAGGCTAT)',
    'N714-S521 (GCTCATGA-GAGCCTTA)',
    'N714-S522 (GCTCATGA-TTATGCGA)',
    'N715-S513 (ATCTCAGG-TCGACTAG)',
    'N715-S515 (ATCTCAGG-TTCTAGCT)',
    'N715-S516 (ATCTCAGG-CCTAGAGT)',
    'N715-S517 (ATCTCAGG-GCGTAAGA)',
    'N715-S518 (ATCTCAGG-CTATTAAG)',
    'N715-S520 (ATCTCAGG-AAGGCTAT)',
    'N715-S521 (ATCTCAGG-GAGCCTTA)',
    'N715-S522 (ATCTCAGG-TTATGCGA)',
    'N716-S502 (ACTCGCTA-CTCTCTAT)',
    'N716-S503 (ACTCGCTA-TATCCTCT)',
    'N716-S505 (ACTCGCTA-GTAAGGAG)',
    'N716-S506 (ACTCGCTA-ACTGCATA)',
    'N716-S507 (ACTCGCTA-AAGGAGTA)',
    'N716-S508 (ACTCGCTA-CTAAGCCT)',
    'N716-S510 (ACTCGCTA-CGTCTAAT)',
    'N716-S511 (ACTCGCTA-TCTCTCCG)',
    'N718-S502 (GGAGCTAC-CTCTCTAT)',
    'N718-S503 (GGAGCTAC-TATCCTCT)',
    'N718-S505 (GGAGCTAC-GTAAGGAG)',
    'N718-S506 (GGAGCTAC-ACTGCATA)',
    'N718-S507 (GGAGCTAC-AAGGAGTA)',
    'N718-S508 (GGAGCTAC-CTAAGCCT)',
    'N718-S510 (GGAGCTAC-CGTCTAAT)',
    'N718-S511 (GGAGCTAC-TCTCTCCG)',
    'N719-S502 (GCGTAGTA-CTCTCTAT)',
    'N719-S503 (GCGTAGTA-TATCCTCT)',
    'N719-S505 (GCGTAGTA-GTAAGGAG)',
    'N719-S506 (GCGTAGTA-ACTGCATA)',
    'N719-S507 (GCGTAGTA-AAGGAGTA)',
    'N719-S508 (GCGTAGTA-CTAAGCCT)',
    'N719-S510 (GCGTAGTA-CGTCTAAT)',
    'N719-S511 (GCGTAGTA-TCTCTCCG)',
    'N720-S502 (CGGAGCCT-CTCTCTAT)',
    'N720-S503 (CGGAGCCT-TATCCTCT)',
    'N720-S505 (CGGAGCCT-GTAAGGAG)',
    'N720-S506 (CGGAGCCT-ACTGCATA)',
    'N720-S507 (CGGAGCCT-AAGGAGTA)',
    'N720-S508 (CGGAGCCT-CTAAGCCT)',
    'N720-S510 (CGGAGCCT-CGTCTAAT)',
    'N720-S511 (CGGAGCCT-TCTCTCCG)',
    'N721-S502 (TACGCTGC-CTCTCTAT)',
    'N721-S503 (TACGCTGC-TATCCTCT)',
    'N721-S505 (TACGCTGC-GTAAGGAG)',
    'N721-S506 (TACGCTGC-ACTGCATA)',
    'N721-S507 (TACGCTGC-AAGGAGTA)',
    'N721-S508 (TACGCTGC-CTAAGCCT)',
    'N721-S510 (TACGCTGC-CGTCTAAT)',
    'N721-S511 (TACGCTGC-TCTCTCCG)',
    'N722-S502 (ATGCGCAG-CTCTCTAT)',
    'N722-S503 (ATGCGCAG-TATCCTCT)',
    'N722-S505 (ATGCGCAG-GTAAGGAG)',
    'N722-S506 (ATGCGCAG-ACTGCATA)',
    'N722-S507 (ATGCGCAG-AAGGAGTA)',
    'N722-S508 (ATGCGCAG-CTAAGCCT)',
    'N722-S510 (ATGCGCAG-CGTCTAAT)',
    'N722-S511 (ATGCGCAG-TCTCTCCG)',
    'N723-S502 (TAGCGCTC-CTCTCTAT)',
    'N723-S503 (TAGCGCTC-TATCCTCT)',
    'N723-S505 (TAGCGCTC-GTAAGGAG)',
    'N723-S506 (TAGCGCTC-ACTGCATA)',
    'N723-S507 (TAGCGCTC-AAGGAGTA)',
    'N723-S508 (TAGCGCTC-CTAAGCCT)',
    'N723-S510 (TAGCGCTC-CGTCTAAT)',
    'N723-S511 (TAGCGCTC-TCTCTCCG)',
    'N724-S502 (ACTGAGCG-CTCTCTAT)',
    'N724-S503 (ACTGAGCG-TATCCTCT)',
    'N724-S505 (ACTGAGCG-GTAAGGAG)',
    'N724-S506 (ACTGAGCG-ACTGCATA)',
    'N724-S507 (ACTGAGCG-AAGGAGTA)',
    'N724-S508 (ACTGAGCG-CTAAGCCT)',
    'N724-S510 (ACTGAGCG-CGTCTAAT)',
    'N724-S511 (ACTGAGCG-TCTCTCCG)',
    'N726-S502 (CCTAAGAC-CTCTCTAT)',
    'N726-S503 (CCTAAGAC-TATCCTCT)',
    'N726-S505 (CCTAAGAC-GTAAGGAG)',
    'N726-S506 (CCTAAGAC-ACTGCATA)',
    'N726-S507 (CCTAAGAC-AAGGAGTA)',
    'N726-S508 (CCTAAGAC-CTAAGCCT)',
    'N726-S510 (CCTAAGAC-CGTCTAAT)',
    'N726-S511 (CCTAAGAC-TCTCTCCG)',
    'N727-S502 (CGATCAGT-CTCTCTAT)',
    'N727-S503 (CGATCAGT-TATCCTCT)',
    'N727-S505 (CGATCAGT-GTAAGGAG)',
    'N727-S506 (CGATCAGT-ACTGCATA)',
    'N727-S507 (CGATCAGT-AAGGAGTA)',
    'N727-S508 (CGATCAGT-CTAAGCCT)',
    'N727-S510 (CGATCAGT-CGTCTAAT)',
    'N727-S511 (CGATCAGT-TCTCTCCG)',
    'N728-S502 (TGCAGCTA-CTCTCTAT)',
    'N728-S503 (TGCAGCTA-TATCCTCT)',
    'N728-S505 (TGCAGCTA-GTAAGGAG)',
    'N728-S506 (TGCAGCTA-ACTGCATA)',
    'N728-S507 (TGCAGCTA-AAGGAGTA)',
    'N728-S508 (TGCAGCTA-CTAAGCCT)',
    'N728-S510 (TGCAGCTA-CGTCTAAT)',
    'N728-S511 (TGCAGCTA-TCTCTCCG)',
    'N729-S502 (TCGACGTC-CTCTCTAT)',
    'N729-S503 (TCGACGTC-TATCCTCT)',
    'N729-S505 (TCGACGTC-GTAAGGAG)',
    'N729-S506 (TCGACGTC-ACTGCATA)',
    'N729-S507 (TCGACGTC-AAGGAGTA)',
    'N729-S508 (TCGACGTC-CTAAGCCT)',
    'N729-S510 (TCGACGTC-CGTCTAAT)',
    'N729-S511 (TCGACGTC-TCTCTCCG)',
    'N716-S513 (ACTCGCTA-TCGACTAG)',
    'N716-S515 (ACTCGCTA-TTCTAGCT)',
    'N716-S516 (ACTCGCTA-CCTAGAGT)',
    'N716-S517 (ACTCGCTA-GCGTAAGA)',
    'N716-S518 (ACTCGCTA-CTATTAAG)',
    'N716-S520 (ACTCGCTA-AAGGCTAT)',
    'N716-S521 (ACTCGCTA-GAGCCTTA)',
    'N716-S522 (ACTCGCTA-TTATGCGA)',
    'N718-S513 (GGAGCTAC-TCGACTAG)',
    'N718-S515 (GGAGCTAC-TTCTAGCT)',
    'N718-S516 (GGAGCTAC-CCTAGAGT)',
    'N718-S517 (GGAGCTAC-GCGTAAGA)',
    'N718-S518 (GGAGCTAC-CTATTAAG)',
    'N718-S520 (GGAGCTAC-AAGGCTAT)',
    'N718-S521 (GGAGCTAC-GAGCCTTA)',
    'N718-S522 (GGAGCTAC-TTATGCGA)',
    'N719-S513 (GCGTAGTA-TCGACTAG)',
    'N719-S515 (GCGTAGTA-TTCTAGCT)',
    'N719-S516 (GCGTAGTA-CCTAGAGT)',
    'N719-S517 (GCGTAGTA-GCGTAAGA)',
    'N719-S518 (GCGTAGTA-CTATTAAG)',
    'N719-S520 (GCGTAGTA-AAGGCTAT)',
    'N719-S521 (GCGTAGTA-GAGCCTTA)',
    'N719-S522 (GCGTAGTA-TTATGCGA)',
    'N720-S513 (CGGAGCCT-TCGACTAG)',
    'N720-S515 (CGGAGCCT-TTCTAGCT)',
    'N720-S516 (CGGAGCCT-CCTAGAGT)',
    'N720-S517 (CGGAGCCT-GCGTAAGA)',
    'N720-S518 (CGGAGCCT-CTATTAAG)',
    'N720-S520 (CGGAGCCT-AAGGCTAT)',
    'N720-S521 (CGGAGCCT-GAGCCTTA)',
    'N720-S522 (CGGAGCCT-TTATGCGA)',
    'N721-S513 (TACGCTGC-TCGACTAG)',
    'N721-S515 (TACGCTGC-TTCTAGCT)',
    'N721-S516 (TACGCTGC-CCTAGAGT)',
    'N721-S517 (TACGCTGC-GCGTAAGA)',
    'N721-S518 (TACGCTGC-CTATTAAG)',
    'N721-S520 (TACGCTGC-AAGGCTAT)',
    'N721-S521 (TACGCTGC-GAGCCTTA)',
    'N721-S522 (TACGCTGC-TTATGCGA)',
    'N722-S513 (ATGCGCAG-TCGACTAG)',
    'N722-S515 (ATGCGCAG-TTCTAGCT)',
    'N722-S516 (ATGCGCAG-CCTAGAGT)',
    'N722-S517 (ATGCGCAG-GCGTAAGA)',
    'N722-S518 (ATGCGCAG-CTATTAAG)',
    'N722-S520 (ATGCGCAG-AAGGCTAT)',
    'N722-S521 (ATGCGCAG-GAGCCTTA)',
    'N722-S522 (ATGCGCAG-TTATGCGA)',
    'N723-S513 (TAGCGCTC-TCGACTAG)',
    'N723-S515 (TAGCGCTC-TTCTAGCT)',
    'N723-S516 (TAGCGCTC-CCTAGAGT)',
    'N723-S517 (TAGCGCTC-GCGTAAGA)',
    'N723-S518 (TAGCGCTC-CTATTAAG)',
    'N723-S520 (TAGCGCTC-AAGGCTAT)',
    'N723-S521 (TAGCGCTC-GAGCCTTA)',
    'N723-S522 (TAGCGCTC-TTATGCGA)',
    'N724-S513 (ACTGAGCG-TCGACTAG)',
    'N724-S515 (ACTGAGCG-TTCTAGCT)',
    'N724-S516 (ACTGAGCG-CCTAGAGT)',
    'N724-S517 (ACTGAGCG-GCGTAAGA)',
    'N724-S518 (ACTGAGCG-CTATTAAG)',
    'N724-S520 (ACTGAGCG-AAGGCTAT)',
    'N724-S521 (ACTGAGCG-GAGCCTTA)',
    'N724-S522 (ACTGAGCG-TTATGCGA)',
    'N726-S513 (CCTAAGAC-TCGACTAG)',
    'N726-S515 (CCTAAGAC-TTCTAGCT)',
    'N726-S516 (CCTAAGAC-CCTAGAGT)',
    'N726-S517 (CCTAAGAC-GCGTAAGA)',
    'N726-S518 (CCTAAGAC-CTATTAAG)',
    'N726-S520 (CCTAAGAC-AAGGCTAT)',
    'N726-S521 (CCTAAGAC-GAGCCTTA)',
    'N726-S522 (CCTAAGAC-TTATGCGA)',
    'N727-S513 (CGATCAGT-TCGACTAG)',
    'N727-S515 (CGATCAGT-TTCTAGCT)',
    'N727-S516 (CGATCAGT-CCTAGAGT)',
    'N727-S517 (CGATCAGT-GCGTAAGA)',
    'N727-S518 (CGATCAGT-CTATTAAG)',
    'N727-S520 (CGATCAGT-AAGGCTAT)',
    'N727-S521 (CGATCAGT-GAGCCTTA)',
    'N727-S522 (CGATCAGT-TTATGCGA)',
    'N728-S513 (TGCAGCTA-TCGACTAG)',
    'N728-S515 (TGCAGCTA-TTCTAGCT)',
    'N728-S516 (TGCAGCTA-CCTAGAGT)',
    'N728-S517 (TGCAGCTA-GCGTAAGA)',
    'N728-S518 (TGCAGCTA-CTATTAAG)',
    'N728-S520 (TGCAGCTA-AAGGCTAT)',
    'N728-S521 (TGCAGCTA-GAGCCTTA)',
    'N728-S522 (TGCAGCTA-TTATGCGA)',
    'N729-S513 (TCGACGTC-TCGACTAG)',
    'N729-S515 (TCGACGTC-TTCTAGCT)',
    'N729-S516 (TCGACGTC-CCTAGAGT)',
    'N729-S517 (TCGACGTC-GCGTAAGA)',
    'N729-S518 (TCGACGTC-CTATTAAG)',
    'N729-S520 (TCGACGTC-AAGGCTAT)',
    'N729-S521 (TCGACGTC-GAGCCTTA)',
    'N729-S522 (TCGACGTC-TTATGCGA)',
  ],
  NoIndex: null,
}
