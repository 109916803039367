import { UserContext } from './contexts/userContext'
export const { REACT_APP_CLINICAL_URL } = process.env

const axios = require('axios').default

const axiosGETContext = (endPoint, { token }: UserContext) => {
  return axios
    .get(endPoint, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => response.data)
}

const axiosPOSTContext = (endPoint, { token }: UserContext, data) => {
  return axios
    .post(endPoint, data, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => response.data)
}

const axiosGET = (endPoint, token) => {
  return axios
    .get(endPoint, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => response.data)
}

export const getCases = async (
  context: UserContext,
  query_string = '',
  action = ''
): Promise<any> => {
  const endPoint =
    action == null
      ? `${REACT_APP_CLINICAL_URL}/cases?enquiry=${query_string}`
      : `${REACT_APP_CLINICAL_URL}/cases?enquiry=${query_string}&action=${action}`
  return axiosGETContext(endPoint, context)
}

export const getCase = async (context: UserContext, caseId): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/cases/${caseId}`
  return axiosGETContext(endPoint, context)
}

export const getUser = async (token): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/me`
  return axiosGET(endPoint, token)
}

export const getSamples = async (context: UserContext, query_string = ''): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/samples?enquiry=${query_string}`
  return axiosGETContext(endPoint, context)
}

export const getSample = async (context: UserContext, sampleId): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/samples/${sampleId}`
  return axiosGETContext(endPoint, context)
}

export const getPools = async (context: UserContext, query_string = ''): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/pools?enquiry=${query_string}`
  return axiosGETContext(endPoint, context)
}

export const getApplications = async (context: UserContext): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/applications`
  return axiosGETContext(endPoint, context)
}

export const getApplication = async (context: UserContext, appId): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/applications/${appId}`
  return axiosGETContext(endPoint, context)
}

export const getOptions = async (context: UserContext): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/options`
  return axiosGETContext(endPoint, context)
}

export const getCollaborationCases = async (
  context: UserContext,
  customer: string,
  orderType: string
): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/families_in_collaboration?customer=${customer}&data_analysis=${orderType}`
  return axiosGETContext(endPoint, context)
}

export const postOrder = async (
  context: UserContext,
  orderType: string,
  body: any
): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/submit_order/${orderType}`
  return axiosPOSTContext(endPoint, context, body)
}

export const getExistingSamples = async (
  context: UserContext,
  customer: string,
  input = ''
): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/samples_in_collaboration?enquiry=${input}&customer=${customer}`
  return axiosGETContext(endPoint, context)
}

export const getExistingCases = async (
  context: UserContext,
  customer: string,
  input = '',
  workflow = ''
): Promise<any> => {
  const endPoint = `${REACT_APP_CLINICAL_URL}/families_in_collaboration?enquiry=${input}&customer=${customer}&data_analysis=${workflow}`
  return axiosGETContext(endPoint, context)
}
