import { validateApplication } from '../../../pages/OrderForm/helpers'
import { Form, Select } from 'antd'
import { getApplication } from '../../../services/StatusDbApi'
import { orderTypes } from '../../../services/helpers/constants'
import React from 'react'

export const ApplicationField = ({
  analysisType,
  index,
  isExisting,
  options,
  setApplication,
  setApplicationObj,
  skipReceptionControl,
  userContext,
}) => {
  return (
    <Form.Item
      label="Application tag"
      name={[index, 'application']}
      rules={[
        { required: true },
        {
          validator: (rule, value) => {
            return isExisting
              ? Promise.resolve()
              : validateApplication(value, options?.applications, analysisType)
          },
        },
      ]}
      required
    >
      <Select
        style={{ width: 170 }}
        disabled={isExisting}
        onChange={async (e) => {
          setApplication(e)
          if (e && skipReceptionControl) {
            const newApplicationObj = await getApplication(userContext, e)
            setApplicationObj(newApplicationObj)
          } else {
            setApplicationObj(null)
          }
        }}
      >
        {orderTypes
          .find((type) => type.orderType === analysisType)
          ?.['applications'].map((application) =>
            options.applications[application]?.map((localApp) => (
              <Select.Option key={localApp} value={localApp}>
                {localApp}
              </Select.Option>
            ))
          )}
      </Select>
    </Form.Item>
  )
}
